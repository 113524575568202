import { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Form, InputGroup, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
	fetchXToken,
	forgotUserPassword,
	loginUser,
	logoutUser,
	registerUser,
	UserContext,
	xTokenContext,
} from "../services/authService";

function Authenticator() {
	const { uname } = useParams();

	const [email, setEmail] = useState("")
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [resetting, setResetting] = useState(false);
	const [loading, setLoading] = useState(false); // processing reset submission
	const [signingUp, setSigningUp] = useState(false);

	const [message, setMessage] = useState(null);
	const [error, setError] = useState(null);

	const { xToken, setXToken } = useContext(xTokenContext);
	const { user, setUser } = useContext(UserContext);

	const navigate = useNavigate();

	useEffect(() => {
		if (xToken) navigate("/");
	}, [xToken]);
	useEffect(() => {
		if (uname) {
			setUsername(uname);
			document.getElementById("passwordField").focus();
		}
	}, []);
	function registerToggle() {
		if (resetting) {
			return setResetting(false);
		}
		setSigningUp(!signingUp);
	}
	function authenticate() {
		if (!signingUp && !resetting) {
			// log in
			loginUser(username, password)
				.then((res) => {
					setMessage("Welcome, " + res.data.user.username);
					setUser(res.data.user);
					setXToken(res.headers["x-token"]);
					localStorage.removeItem("refreshFail");
					navigate("/");
				})
				.catch((err) => {
					setError(err.response.data);
				});
		} else if (resetting) {
			// password reset
			forgotUserPassword(username).then((res) => {
				setResetting(false);
				setLoading(true);
				setError(null);
			})
			.catch(err => {
				setError(err.response.data);
			});
		} else {
			// sign up
			var cpass = document.getElementById("cpasswordField");
			if (password !== cpass.value) {
				return cpass.focus();
			}
			const newUser = {
				email: email,
				password: password,
				username: username,
			};
			registerUser(newUser)
				.then((res) => {
					setMessage(res.data);
					setSigningUp(false);
				})
				.catch((err) => alert(err.response.data));
		}
	}
	return (
		<>
			<div>
				<Card className="cmodal authform">
					<div className="head v-flex">
						<img
							src="/splash.png"
							style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
						/>
					</div>
					{message && (
						<Alert variant="info">
							<Alert.Heading>{message}</Alert.Heading>
						</Alert>
					)}
					{error && (
						<Alert
							variant="danger"
							style={{ margin: 10 }}
							onClick={() => setError(null)}
						>
							<Alert.Heading>{error}</Alert.Heading>
						</Alert>
					)}
					<Form>
						{(signingUp || resetting) && (
							<Form.Group controlId="emailField">
								<Form.Label>
									{resetting ? (
										<>
											<i className="fa-solid fa-key"></i> Let's reset your
											password
										</>
									) : (
										"Email"
									)}
								</Form.Label>
								<Form.Control
									type="text"
									placeholder="username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									disabled={loading}
								/>
							</Form.Group>
						)}
						{signingUp && (
							<Form.Group controlId="compName">
								{/* <Form.Label>Company Name</Form.Label> */}
								<Form.Control
									type="text"
									placeholder="Company Name..."
									value={username}
									onChange={(e) => setUsername(e.target.value)}
								/>
							</Form.Group>
						)}
						{!resetting && loading && (
							<>
								<h2 className="m-0">
									<i className="fa-solid fa-envelope"></i> Check your email
								</h2>
								<small>
									A link has been sent shortly, please check your spam folder.
								</small>
								<Button
									className="btn-lg"
									variant="secondary"
									onClick={() => setLoading(false)}
									style={{ borderRadius: 0 }}
								>
									Continue
								</Button>
							</>
						)}
						{!resetting && !loading && (
							<>
								<InputGroup>
									<InputGroup.Text>
										<i className="fa-solid fa-at" />
									</InputGroup.Text>
									<Form.Control
										type="email"
										placeholder="Username..."
										value={username}
										defaultValue={uname || ""}
										id="userField"
										onChange={(e) => setUsername(e.target.value)}
									/>
								</InputGroup>
								<InputGroup>
									<InputGroup.Text>
										<i className="fa-solid fa-key" />
									</InputGroup.Text>
									<Form.Control
										type="password"
										placeholder="Password..."
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										id="passwordField"
										onKeyDown={(e) => {
											if (!signingUp && e.code === "Enter") authenticate();
										}}
									/>
								</InputGroup>
							</>
						)}
						{!signingUp && !resetting && !loading && (
							<a
								href="#"
								onClick={() => {
									setResetting(true);
									setTimeout(() => {
										document.getElementById("emailField")?.focus();
									}, 400);
								}}
							>
								Forgot password?
							</a>
						)}
						{signingUp && (
							<Form.Group controlId="cpasswordField">
								<Form.Label>Repeat Password</Form.Label>
								<Form.Control type="password" />
							</Form.Group>
						)}
					</Form>
					{!user && !loading && (
						<Stack
							direction="horizontal"
							style={{ display: "flex", justifyContent: "center" }}
							className="footer"
						>
							<Button variant="primary" onClick={authenticate}>
								{!signingUp ? (!resetting ? "Log In" : "Send Link") : "Confirm"}
							</Button>
							<Button variant="secondary" onClick={registerToggle}>
								{!signingUp && !resetting ? "Register" : "Cancel"}
							</Button>
						</Stack>
					)}
				</Card>
			</div>
		</>
	);
}

export default Authenticator;
