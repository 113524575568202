import { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Form, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
	fetchXToken,
	forgotUserPassword,
	loginUser,
	logoutUser,
	registerUser,
	submitForgetToken,
	UserContext,
	xTokenContext,
} from "../services/authService";

function ForgotPass() {
	const { token } = useParams();

	const [password, setPassword] = useState("");
	const [cPassword, setCPassword] = useState("");
	const [done, setDone] = useState(false);
	const [loading, setLoading] = useState(false); // processing reset submission

	const [message, setMessage] = useState(null);

	const { xToken, setXToken } = useContext(xTokenContext);
	const { user, setUser } = useContext(UserContext);

	const navigate = useNavigate();

	useEffect(() => {
		if (xToken) navigate("/");
	}, [xToken]);

	function submit() {
		if (!password) return document.getElementById("npass")?.focus();
		if (password !== cPassword)
			return document.getElementById("cpass")?.focus();
		setLoading(true);
		setTimeout(() => {
			submitForgetToken(token, password)
				.then((res) => {
					setDone(true);
					// setLoading(false);
					localStorage.setItem("username", res.data); // will return the username
				})
				.catch((e) => {
					setMessage(e.response.data);
				});
		}, 500);
	}
	return (
		<>
			<div>
				<Card className="cmodal authform">
					<div className="head v-flex">
						<img
							src="/splash.png"
							style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
						/>
					</div>
					{message && (
						<Alert variant="danger" style={{ margin: 10 }}>
							<Alert.Heading>{message}</Alert.Heading>
						</Alert>
					)}
					<Form>
						{done && loading && (
							<>
								<h2 className="m-0">
									<i className="fa-solid fa-lock"></i> Account Secured
								</h2>
								<small>Please login with your new password...</small>
								<Button
									className="btn-lg"
									variant="secondary"
									onClick={() =>
										navigate("/auth/" + localStorage.getItem("username"))
									}
									style={{ borderRadius: 0 }}
								>
									Continue
								</Button>
							</>
						)}
						{!done && (
							<>
								<Form.Group controlId="passwordField">
									<Form.Label>
										<i className="fa-solid fa-key"></i> Choose a new password
									</Form.Label>
									<Form.Control
										type="password"
										placeholder="New password..."
										value={password}
										id="npass"
										onChange={(e) => setPassword(e.target.value)}
										onKeyDown={(e) => {
											if (e.code === "Enter") submit();
										}}
										disabled={loading}
									/>
								</Form.Group>

								<Form.Group controlId="cpasswordField">
									<Form.Control
										type="password"
										id="cpass"
										placeholder="Repeat password..."
										value={cPassword}
										onChange={(e) => setCPassword(e.target.value)}
										disabled={loading}
										onKeyDown={(e) => {
											if (e.code === "Enter") submit();
										}}
									/>
								</Form.Group>
							</>
						)}
					</Form>
					{!user && !loading && (
						<Stack
							direction="horizontal"
							style={{ display: "flex", justifyContent: "center" }}
							className="footer"
						>
							<Button variant="primary" onClick={submit}>
								Confirm
							</Button>
						</Stack>
					)}
				</Card>
			</div>
		</>
	);
}

export default ForgotPass;
