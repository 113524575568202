import { Alert, Button, Card, Form, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { consumeInviteToken } from "../services/authService";
import { useState } from "react";

function InvitePage() {
	const { token } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const [message, setMessage] = useState("");
	function _confirmation() {
		var pass1 = document.getElementById("val1") as HTMLInputElement;
		var pass2 = document.getElementById("val2") as HTMLInputElement;
		if (!pass1.value) return pass1.focus();
		if (pass1.value !== pass2.value) return pass2.focus();

		if (token) {
			setLoading(true);
			setTimeout(() => {
				consumeInviteToken(token, pass1.value)
					.then((res) => {
						setDone(true);
						localStorage.setItem("username", res.data);
					})
					.catch((err) => {
						setMessage(err.response.data);
					});
			}, 500);
		}
	}
	return (
		<>
			<div>
				<Card className="cmodal authform">
					<div className="head v-flex">
						<img
							src="/splash.png"
							style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
						/>
					</div>
					{message && (
						<Alert variant="danger" style={{ margin: 10 }}>
							<Alert.Heading>{message}</Alert.Heading>
						</Alert>
					)}
					<Form>
						{done && loading && (
							<>
								<h2 className="m-0">
									<i className="fa-solid fa-lock"></i> Account Secured
								</h2>
								<small>Please login with your new password...</small>
								<Button
									className="btn-lg"
									variant="secondary"
									onClick={() =>
										navigate("/auth/" + localStorage.getItem("username"))
									}
									style={{ borderRadius: 0 }}
								>
									<i className="fa-solid fa-right-to-bracket"></i> Proceed
								</Button>
							</>
						)}
						{!done && (
							<>
								<Form.Group controlId="passwordField">
									<Form.Label>
										<i className="fa-solid fa-key"></i> Choose your account
										password
									</Form.Label>
									<Form.Control
										type="password"
										placeholder="New password..."
										id="val1"
										onKeyDown={(e) => {
											if (e.code === "Enter")
												document.getElementById("cpass")?.focus();
										}}
										disabled={loading}
									/>
								</Form.Group>

								<Form.Group controlId="cpasswordField">
									<Form.Control
										type="password"
										id="val2"
										placeholder="Repeat password..."
										disabled={loading}
										onKeyDown={(e) => {
											if (e.code === "Enter") _confirmation();
										}}
									/>
								</Form.Group>
							</>
						)}
					</Form>
					{!loading && (
						<Stack
							direction="horizontal"
							style={{ display: "flex", justifyContent: "center" }}
							className="footer"
						>
							<Button variant="primary" onClick={_confirmation}>
								Confirm
							</Button>
						</Stack>
					)}
				</Card>
			</div>
		</>
	);
}

export default InvitePage;
