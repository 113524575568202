import axios from "axios";
import { createContext, Dispatch, SetStateAction } from "react";
import { serverDomain } from "../fSettings";
import { CustomerItem } from "./custService";
import { UserData } from "./authService"

const apiUrl = `${serverDomain}/api/sort`;

const auth = axios.create({ withCredentials: true });

export type PurchaseOrder = {
	purchaseOrder: string;
	enterprise: string;
};
export type PartNumber = {
	pn: string;
	revision: string;
};
export type SortItem = {
	approved: boolean;
	denied: boolean;
	pendingComment: string;
	pendingBy: string;
	requestedBy: string;
	hourly: boolean;
	enterprise: string;
	createDate: Date;
	startDate: string;
	endDate: string;
	purchaseOrders: string[];
	poComments: string;
	regularRate: string;
	supervisorRate: string;
	partNumbers: PartNumber[];
	sortNumber: string;
	sortDescription: string;
	scarNumber: string;
	requestLocation: string;
	locations: string[];
	files: string[];
	cleanPoint?: string;
	nonconfCondition: string;
	headCount: number;
	// cycleTime: number;
	boxPieces: number;
	relabel: boolean;
	submittedForApproval: boolean;
	open: boolean;
	supName: string;
	gsaSortNum?: string;
	closed?: boolean;
	supInternalNum?: string;
	adminNotesOnPo?: string;
};
export type PcsSort = {
	SONO: number;
	PONO: string;
	SortNumber: string;
	Date: string;
	Description: string;
	File: string;
	File2: string;
	id_customer: number;
	closed: boolean;
	sort_type: string;
	supInternalNum: string;
	is_internal: boolean;
	SUPID: string;
};
type Cable = {
	SLP: string;
	SLP_DATE: Date;
	PartNumber: string;
	Serie: string;
	Serie_date: string;
};
export type CableReject = {
	SLP: string;
	Date: string;
	Description: string;
	photo: string;
	photo2: string;
};
export type PassDetail = {
	p_sort: Number;
	p_partnumber: string;
	p_qty: number;
	p_qty_rj: number;
	p_technicians: Number;
	p_hours: number;
	p_total_hours: number;
	production: Cable[];
	rejects: CableReject[];
};
export type Passdown = {
	id_pass: string;
	shift: string;
	notes: string;
	supervisor: string;
	p_date: string;
	SortNumber: string,
	detail: PassDetail[];
};
//type of passdowns and passdown details Tadashi added
export type passdown = {
	id_pass: string,
	p_date: string,
	p_shift: string,
	SortNumber: string
}

export type PassdownDetail = {
	id_pass: Number,
	id_passd_dtl: Number,
	//invoice: string,
	p_date: string,
	p_hours: 0,
	p_location: string,
	p_notes: string,
	p_partnumber: string,
	p_qty: Number,
	p_qty_rj: Number,
	p_shift: string,
	p_technicians: Number,
	p_total_hours: Number,
	week_day: string
}

export type SortedRejectedQty = {
	p_partnumber: string,
	qty: Number,
	qty_rj: Number
}

export type HourPerWeekday = {
	p_date: string,
	p_partnumber: string,
	total_hours: number,
	weekday: string
}

export async function searchForSorts(searchText: string, token: string) {
	return auth.post(
		`${apiUrl}/search`,
		{ searchText },
		{
			headers: { "x-token": token },
		}
	);
}
export async function transferSort(
	newCustomer: String,
	sortNumber: string,
	token: string
) {
	return auth.post(
		`${apiUrl}/transfer/${encodeURIComponent(sortNumber)}`,
		{ customerID: newCustomer },
		{
			headers: { "x-token": token },
		}
	);
}

export async function transferSortSupplier(
	newCustomer: String,
	sortNumber: string,
	token: string
) {
	return auth.post(
		`${apiUrl}/transfer-supplier/${encodeURIComponent(sortNumber)}`,
		{ supplierID: newCustomer },
		{
			headers: { "x-token": token },
		}
	);
}

export async function updateSort(
	sortNumber: string,
	data: Object,
	token: string
) {
	return auth.post(`${apiUrl}/update/${encodeURIComponent(sortNumber)}`, data, {
		headers: { "x-token": token },
	});
}
export async function createSort(
	data: Object,
	customerID: String,
	token: string
) {
	return auth.post(`${apiUrl}/create/${customerID}`, data, {
		headers: { "x-token": token },
	});
}
export async function createSortSupplier(
	data: Object,
	supplierID: String,
	token: string
) {
	return auth.post(`${apiUrl}/createsortsupplier/${supplierID}`, data, {
		headers: { "x-token": token },
	});
}
export async function uploadFiles(
	sortId: string,
	data: FormData,
	token: string
) {
	return auth.post(`${apiUrl}/upload/${sortId}`, data, {
		headers: { "x-token": token },
	});
}
export async function downloadFile(
	enterpriseId: string,
	sortId: string,
	fileName: string,
	token: string
) {
	return auth.get(`${apiUrl}/download/${enterpriseId}/${sortId}/${fileName}`, {
		responseType: "blob",
		headers: { "x-token": token },
	});
}
export async function deleteFile(
	sortId: string,
	fileName: string,
	token: string
) {
	return auth.post(
		`${apiUrl}/delfile/${sortId}`,
		{ fileName },
		{
			headers: { "x-token": token },
		}
	);
}
export async function fetchPurchaseOrders(customerId: string, token: string) {
	var custid = ''
	if (customerId.trim() === '') {
		custid = 'no_id'
	} else {custid = customerId}
	return auth.get(`${apiUrl}/pos/${custid}`, {
		headers: { "x-token": token },
	});
}
// for admins only, retrieve approved sorts of current month
export async function fetchRecentSorts(token: string) {
	return auth.get(`${apiUrl}/recent`, {
		headers: { "x-token": token },
	});
}
// for admins only, retrieve all pending sorts
export async function fetchOpenSorts(token: string) {
	return auth.get(`${apiUrl}/open`, {
		headers: { "x-token": token },
	});
}
export async function fetchSorts(customerId: String, token: string) {
	return auth.get(`${apiUrl}/fetch/${customerId}`, {
		headers: { "x-token": token },
	});
}


export async function fetchSortsSupplier(supplierId: string, token: string) {
	return auth.get(`${apiUrl}/fetchsupplier/${supplierId}`, {
		headers: { "x-token": token },
	});
}

export async function approveSort(
	sortNumber: string,
	approve: boolean,
	hourly: boolean,
	token: string,
	locations: string[] | undefined = undefined
) {
	return auth.post(
		`${apiUrl}/approve/${encodeURIComponent(sortNumber)}`,
		{ approve, hourly, locations },
		{ headers: { "x-token": token } }
	);
}

export async function approveSortSupplier(
	sortNumber: string,
	approve: boolean,
	hourly: boolean,
	token: string,
	locations: string[] | undefined = undefined
) {
	return auth.post(
		`${apiUrl}/approve-supplier/${encodeURIComponent(sortNumber)}`,
		{ approve, hourly, locations },
		{ headers: { "x-token": token } }
	);
}

export async function holdSort(
	sortNumber: string,
	hold: boolean,
	comment: string,
	token: string
) {
	return auth.post(
		`${apiUrl}/hold/${encodeURIComponent(sortNumber)}`,
		{ hold, comment },
		{ headers: { "x-token": token } }
	);
}


export async function holdSortSupplier(
	sortNumber: string,
	hold: boolean,
	comment: string,
	token: string
) {
	return auth.post(
		`${apiUrl}/hold-supplier/${encodeURIComponent(sortNumber)}`,
		{ hold, comment },
		{ headers: { "x-token": token } }
	);
}

export async function genSortReport(
	sortNumber: string,
	passdownDetails: PassdownDetail[],
	customerID: string,
	token: string
) {
	return auth.post(`${apiUrl}/report`, {
		sortNumber,
		passdownDetails,
		customerID
	}, { headers: { "x-token": token } });
}

export async function genSortReportSupplier(
	sortNumber: string,
	passdownDetails: PassdownDetail[],
	_id: string,
	token: string
) {
	return auth.post(`${apiUrl}/reportsupplier`, {
		sortNumber,
		passdownDetails,
		_id
	}, { headers: { "x-token": token } });
}

export async function markSortForApproval(
	sortNumber: string | undefined,
	token: string | null
) {
	return auth.post(
		`${apiUrl}/mark-for-approval`,
		{ sortNumber },
		{
			headers: { "x-token": token },
		}
	);
}


export async function markSortForApprovalSupplier(
	sortNumber: string | undefined,
	token: string | null
) {
	return auth.post(
		`${apiUrl}/mark-for-approval-supplier`,
		{ sortNumber },
		{
			headers: { "x-token": token },
		}
	);
}

export async function saveAdminNote(
	sortId: String,
	comment: String,
	token: string
) {
	return auth.post(`${apiUrl}/saveAdminNote/${sortId}`, {comment}, {
		headers: { "x-token": token },
	});
}

export function disableField (user: UserData | null, curSort: SortItem | null) {
	return (!user?.admin &&
													curSort &&
													(curSort.approved ||
														curSort.denied ||
														(curSort.submittedForApproval && curSort.pendingBy === "")) ||
												false)
}
// export async function fetchCustomers(token: string){
//     return auth.get(`${apiUrl}/fetch`, {headers: {"x-token": token}});
// }