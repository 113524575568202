import { useState, useEffect, useContext } from "react"
import { fetchSortsAndPos } from "../services/SortsAndPosService"
import { viewCustomer, CustomerItem } from "../services/custService";
import { xTokenContext } from "../services/authService";
import { useParams } from "react-router";
import ErrorToast from "../components/ErrorToast"
import SortsAndPosTable from "../components/SortsAndPosTable";
import Spinner from 'react-bootstrap/Spinner';

const SortsAndPos = () => {

    const { xToken, setXToken } = useContext(xTokenContext);
    const { customerID } = useParams();
    const [customer, setCustomer] = useState<CustomerItem>()
    const [hasError, setHasError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [data, setData] = useState([])
    const [openSortsWithoutInvoiceables, setOpenSortsWithoutInvoiceables] = useState([])
    const [totalUninvoiced, setTotalUninvoiced] = useState(0)
    const [openPos, setOpenPos] = useState<any[]>([])
    const [totalOpenPoBalance, setTotalOpenPoBalance] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        
        if (xToken && customerID) {
            setLoading(true)

            viewCustomer(customerID, xToken).then(res => {
                setCustomer(res.data)
            }).catch(err => {
                setHasError(true)
                setErrorMessage(err.response.data)
                console.log(err.response.data)
                setLoading(false);
                return
            })

            fetchSortsAndPos(xToken, customerID).then(res => {
                setData(res.data.invoiceables)
                setOpenSortsWithoutInvoiceables(res.data.open_sorts_without_invoiceables)
                setTotalUninvoiced(res.data.total_uninvoiced)
                setOpenPos(res.data.open_sort_pos)
                setHasError(false)
                setErrorMessage('')
            }).catch(err => {
                setHasError(true)
                setErrorMessage(err.response.data)
                console.log(err.response.data)
            }).finally(() => {
                setLoading(false)
            });
        }
        
    }, [xToken, customerID])

    useEffect(() => {
        let _total = 0
        openPos.forEach((po) => _total = _total + po.po_balance)
        setTotalOpenPoBalance(_total)
    }, [openPos])

    if (loading) return <Spinner animation="border" variant="info"><span className="visually-hidden">Loading...</span></Spinner>
    return (
        <>
            {customer && <h3>{customer.supName}</h3>}
            <SortsAndPosTable 
                data={data} 
                openSortsWithoutInvoiceables={openSortsWithoutInvoiceables} 
                totalUninvoiced={totalUninvoiced}
                openPos={openPos}
                totalOpenPoBalance={totalOpenPoBalance} />
            <ErrorToast 
                show={hasError} 
                message={errorMessage} 
                closeErrorToast={() => {
                    setHasError(false)
                    setErrorMessage('')
                }} 
            />
        </>
    )
}

export default SortsAndPos