import axios from "axios";

import { serverDomain } from "../fSettings";

import { createContext, Dispatch, SetStateAction } from "react";

const apiUrl = `${serverDomain}/api/auth`;

const auth = axios.create({ withCredentials: true });

export interface IXTokenContext {
	xToken: string | null;
	setXToken?: Dispatch<SetStateAction<null>>;
}
export const xTokenContext = createContext<IXTokenContext>({ xToken: null });

export type UserData = {
	_id: string;
	email: String;
	username: String;
	admin: Boolean;
	enterprise: string;
	supid?: string;
	lastLogin: string;
	firstName?: string;
	lastName?: string;
	title?: string;
	phone?:string;
};
export interface IUserContext {
	user: UserData | null;
	setUser?: Dispatch<SetStateAction<UserData | null>>;
}
export const UserContext = createContext<IUserContext>({ user: null });

export async function fetchXToken() {
	return auth.get(`${apiUrl}/token`);
}
export async function fetchUser(id: String, token: string[]) {
	return auth.get(`${apiUrl}/view/${id}`, {
		headers: { "x-token": token },
	});
}

export async function fetchUsers(token: string) {
	return auth.get(`${apiUrl}/fetch`, { headers: { "x-token": token } });
}
export async function fetchInactiveUsers(token: string) {
	return auth.get(`${apiUrl}/fetch/inactive`, {
		headers: { "x-token": token },
	});
}
export async function deleteUser(userID: string, token: string) {
	return auth.delete(`${apiUrl}/delete/${userID}`, {
		headers: { "x-token": token },
	});
}
export async function promoteUser(
	userID: string,
	enterpriseID: string,
	token: string
) {
	return auth.post(
		`${apiUrl}/promote/${userID}`,
		{ enterprise: enterpriseID },
		{ headers: { "x-token": token } }
	);
}
export async function demoteUser(userID: string, token: string) {
	return auth.get(`${apiUrl}/demote/${userID}`, {
		headers: { "x-token": token },
	});
}

export async function logoutUser() {
	return auth.post(`${apiUrl}/logout`);
}
export async function loginUser(user: string, pass: string) {
	return auth.post(`${apiUrl}/login`, { username: user, password: pass });
}
export async function forgotUserPassword(username: string) {
	return auth.post(`${apiUrl}/forgotpass`, { username });
}
export async function submitForgetToken(token: string, password: string) {
	return auth.post(`${apiUrl}/forgotpass/${token}`, { password });
}
export async function resetUserPassword(
	userID: string,
	oldPass: string,
	newPass: string,
	token: string
) {
	return auth.post(
		`${apiUrl}/resetpass/${userID}`,
		{
			currentPassword: oldPass,
			newPassword: newPass,
		},
		{ headers: { "x-token": token } }
	);
}
export async function searchInviteToken(userID: string, accessToken: string) {
	return auth.get(`${apiUrl}/token/find/${userID}`, {
		headers: { "x-token": accessToken },
	});
}
export async function sendInviteLink(tokenID: string, accessToken: string) {
	return auth.get(`${apiUrl}/sendinvite/${tokenID}`, {
		headers: { "x-token": accessToken },
	});
}
export async function consumeInviteToken(token: string, password: string) {
	return auth.post(`${apiUrl}/invite/${token}`, { password: password });
}
export async function createUser(user: any, for_supplier: boolean, token: string) {
	return auth.post(`${apiUrl}/create`, {user, for_supplier}, { headers: { "x-token": token } });
}
export async function registerUser(_user: Object) {
	return auth.post(`${apiUrl}/register`, _user);
}
