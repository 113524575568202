import {
	Button,
	Form,
	Modal
} from "react-bootstrap";
import { PortalCustomerContact } from '../services/custService'

export interface EditContactModalProps {
    show: boolean;
    contact: PortalCustomerContact;
    handleClosingEditContactModal: () => void;
    handleChangetoContactGettingEdited: (newValue: object) => void;
    saveEditedContact: () => void;
}

export const EditContactModal = (props: EditContactModalProps) => {

    return (
        <Modal show={props.show} onHide={props.handleClosingEditContactModal}>
            <Modal.Body>
                <Form className="mb-2">
                    <Form.Group className="mb-3" controlId="FIRSTNAME">
                        <Form.Label className="freq">First Name</Form.Label>
                        <Form.Control type="text" value={props.contact.FIRSTNAME} onChange={(e) => props.handleChangetoContactGettingEdited({FIRSTNAME: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="LASTNAME">
                        <Form.Label className="freq">Last Name</Form.Label>
                        <Form.Control type="text" value={props.contact.LASTNAME} onChange={(e) => props.handleChangetoContactGettingEdited({LASTNAME: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="EMAIL">
                        <Form.Label className="freq">Email</Form.Label>
                        <Form.Control type="text" value={props.contact.EMAIL} onChange={(e) => props.handleChangetoContactGettingEdited({EMAIL: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="WORKPHONE">
                        <Form.Label >Phone</Form.Label>
                        <Form.Control type="text" value={props.contact.WORKPHONE} onChange={(e) => props.handleChangetoContactGettingEdited({WORKPHONE: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="TITLE">
                        <Form.Label >Title</Form.Label>
                        <Form.Control type="text" value={props.contact.TITLE} onChange={(e) => props.handleChangetoContactGettingEdited({TITLE: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="DEPARTMENT">
                        <Form.Label >Department</Form.Label>
                        <Form.Control type="text" value={props.contact.DEPARTMENT} onChange={(e) => props.handleChangetoContactGettingEdited({DEPARTMENT: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="CONTNOTE">
                        <Form.Label >Notes</Form.Label>
                        <Form.Control type="text" value={props.contact.CONTNOTE} onChange={(e) => props.handleChangetoContactGettingEdited({CONTNOTE: e.target.value})}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.saveEditedContact}>Save changes</Button>
            </Modal.Footer>
        </Modal>
    )
}