import { Alert, Table } from 'react-bootstrap'
import { SortAndPoRow } from '../services/SortsAndPosService'
import { useState } from 'react'

export interface Props {
    data: SortAndPoRow[],
    openSortsWithoutInvoiceables: any[],
    totalUninvoiced: number,
    openPos: any[],
    totalOpenPoBalance: number
}

const SortsAndPosTable = (props: Props) => {

    const rows = props.data.map((row, index) => {
        const showSort = index === 0 || row.sort !== props.data[index - 1].sort
        return (
            <tr>
                <td>{showSort ? row.sort : ''}</td>
                <td>{showSort ? row.invoiced + row.uninvoiced : ''}</td>
                <td>{showSort ? row.invoiced : ''}</td>
                <td>{showSort ? row.uninvoiced : ''}</td>
                <td>{row.po ? row.po : ''}</td>
                <td>{row.po_balance ? row.po_balance : ''}</td>
            </tr>
        )
    })

    const rows2 = props.openSortsWithoutInvoiceables.map((row, index) => (
        <tr>
            <td>{row.SortNumber}</td>
            <td></td>
            <td></td>
        </tr>
    ))

    const rows3 = () => {
        if (props.openPos.length <= 0) {
            return (
                <tr>
                    <td colSpan={4}>No open sorting POs found</td>
                </tr>
            )
        }
        return props.openPos.map((row) => (
                <tr>
                    <td>{row.pono}</td>
                    <td>{row.soamount}</td>
                    <td>{row.total_invoiced}</td>
                    <td>{row.po_balance}</td>
                </tr>
            )
        )
    }

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between', gap: '5%', width: '100%'}}>
                <div style={{width: '70%'}}>
                    <h4 className="item-left">Invoiced & Uninvoiced</h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Sort #</th>
                                <th>Total</th>
                                <th>Invoiced</th>
                                <th>Uninvoiced</th>
                                <th>PO #</th>
                                <th>PO Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                            <tr>
                                <th colSpan={3}>Total Uninvoiced</th>
                                <th>{props.totalUninvoiced}</th>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                    <Alert variant='warning' className="item-left">
                        Following sorts are still open but could not get invoiced & uninvoiced amount possibly due to some type of data mis-entry.
                    </Alert>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{width: '30%'}}>Sort Number</th>
                                <th style={{width: '5%'}}>Investigate</th>
                                <th>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows2}
                        </tbody>
                    </Table>
                </div>
                <div style={{width: '30%'}}>
                    <h4>Open POs</h4>
                    <Table striped bordered hover>
                        <thead>
                            <th>PO #</th>
                            <th>Original</th>
                            <th>Consumed</th>
                            <th>Balance</th>
                        </thead>
                        <tbody>
                            {rows3()}
                            <tr>
                                <th colSpan={3}>Total Balance</th>
                                <th>{props.totalOpenPoBalance}</th>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </>

    )

}

export default SortsAndPosTable