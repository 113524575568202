import axios from "axios";
import { serverDomain } from "../fSettings";

const apiUrl = `${serverDomain}/api/manex`;

const auth = axios.create({withCredentials: true});

export async function fetchManexCustomers(token: string){
    return auth.get(`${apiUrl}/fetch`, {headers: {"x-token": token}});
}


export async function fetchManexSuppliers(token: string){
    return auth.get(`${apiUrl}/fetchsup`, {headers: {"x-token": token}});
}

export async function fetchSortPassdowns(sortNum: String, token: string) {
    return auth.post(`${apiUrl}/sort/${sortNum}`, {headers: {"x-token": token}});
}

export async function fetchSortSupplierPassdowns(sortNum: String, token: string) {
    return auth.post(`${apiUrl}/sortsupplier/${sortNum}`, {headers: {"x-token": token}});
}

