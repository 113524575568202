import Toast from 'react-bootstrap/Toast';

export interface Props {
    message: string;
    show: boolean;
    closeErrorToast: () => void;
}

const ErrorToast = (props: Props) => {
    return (
        <Toast show={props.show} bg="danger" onClose={props.closeErrorToast}>
            <Toast.Header>
                <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                <strong className="me-auto">Error!</strong>
            </Toast.Header>
            <Toast.Body>{props.message}</Toast.Body>
        </Toast>
    )
}

export default ErrorToast