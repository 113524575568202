import { Key, useContext, useEffect, useState } from "react";
import {
	Accordion,
	Button,
	Badge,
	Dropdown,
	Form,
	InputGroup,
	Modal,
	Row,
	Spinner,
	Stack,
	Table,
	Toast,
	ToastContainer,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
	xTokenContext,
	UserData,
	UserContext,
	demoteUser,
	createUser,
} from "../services/authService";
import {
	fetchCustomers,
	CustomerItem,
	ICustomer,
	verifyCustomer,
	ManexCustomer,
	fetchCustomerUsers,
	CustomersContext,
	CustomerContext,
	PcsServiceAgreement,
	hasValidServiceAgreement,
	fetchContacts,
	PortalCustomerContact,
	ManexCustomerContact
} from "../services/custService";
import { fetchManexCustomers } from "../services/manexService";
import CustomerInfoForm from "./CustForm";
import LinkManexCustForm from "../components/LinkManexCustForm";

function CustomersPage() {
	const { user, setUser } = useContext(UserContext);
	const { xToken, setXToken } = useContext(xTokenContext);
	const { customers, setCustomers } = useContext(CustomersContext);
	const [custEnableToggle, setCustEnableToggle] = useState<boolean>(false);
	const [hideServiceAgreementUploaded, setHideServiceAgreementUploaded] = useState<boolean>(false);

	const [create, setCreate] = useState<boolean>(false);
	const [create2, setCreate2] = useState<boolean>(false);
	const [newUser, setNewUser] = useState<boolean>(false);
	const [toast, setToast] = useState("");

	const [manexSearch, setManexSearch] = useState<string>("");
	const [search, setSearch] = useState<string>("");
	const [manex, setManex] = useState<boolean>(false);
	const [showManex, setShowManex] = useState<boolean>(false);
	const [manexCustomers, setManexCustomers] = useState<ManexCustomer[]>([]);

	const { customer, setCustomer } = useContext(CustomerContext); // user's assigned customer
	const [_customer, _setCustomer] = useState<CustomerItem | null>(null); // selected view customer
	const [contacts, setContacts] = useState<PortalCustomerContact[] | ManexCustomerContact[]>([])
	const [contactsFromManex, setContactsFromManex] = useState<boolean | null>(null)

	const [userView, setUserView] = useState<boolean>(false);
	const [users, setUsers] = useState<UserData[]>([]);
	const [serviceAgreements, setServiceAgreements] = useState<PcsServiceAgreement[]>([])
	const [showServiceAgreements, setShowServiceAgreements] = useState<boolean>(false)
	useEffect(() => {
		if (xToken && setCustomers) {
			fetchCustomers(xToken).then((res) => setCustomers(res.data));
		}
	}, [xToken]);
	function handleClose(imported: boolean = false) {
		setCreate(false);
		setContacts([])
		_setCustomer(null);
		setContactsFromManex(null)

		setCreate2(false);
		setManex(false);
		setUserView(false);
		setShowServiceAgreements(false)
		if (imported) setShowManex(false);
	}
	function handleVerify(customerID: String, e: any) {
		const status = e.target.checked;
		console.log(e.target.checked, customerID);
		if (xToken)
			verifyCustomer(xToken, customerID, e.target.checked)
				.then((res) => {
					console.log(res.data);
					let _custs = [...customers];
					let _custIndex = _custs.findIndex((c) => c._id === customerID);
					_custs[_custIndex].verified = status;
					if (setCustomers) setCustomers(_custs);
					if (customerID === user?.enterprise && setCustomer)
						// @ts-ignore
						setCustomer(_custs[_custIndex]);
					setToast(`Customer ${status ? "verified" : "unverified"}!`);
				})
				.catch((err) => {
					alert("Please refresh page");
				});
	}
	function handleDelete(customerID: string) {
		if (setCustomers)
			setCustomers(customers.filter((cust) => cust._id !== customerID));
		setToast(`Deleted customer.`);
		handleClose();
	}
	function show_new_customer(cust: CustomerItem) {
		var _custs = [...customers];
		_custs.push(cust);
		setToast(`Created customer '${cust.supName}'`);
		if (setCustomers) setCustomers(_custs);
	}
	function update_customer(cust: CustomerItem) {
		let _custs = [...customers];
		let updateIndex = _custs.findIndex((c) => c._id === cust._id);
		_custs[updateIndex] = cust; // change to updated document returned from backend
		setToast(`Saved customer '${cust.supName}'`);
		if (setCustomers) setCustomers(_custs);
		// @ts-ignore
		if (setCustomer && cust._id === user?.enterprise) setCustomer(cust);
		console.log("updated list", _custs);
	}
	function populate_customer(cust: ManexCustomer) {
		var _customer: CustomerItem = {
			_id: "",
			supName: cust.CUSTNAME,
			supAddress: cust.ADDRESS1,
			supCity: cust.CITY,
			supState: cust.STATE,
			supCountry: cust.COUNTRY,
			regularRate: 0.0,
			supervisorRate: 0.0,
			verified: false,
			mustAlwaysProvideSortNum: true,
			manexCustomerId: cust.CUSTNO[0],
			serviceAgreement: ""
		};
		// var keys = Object.keys(_customer);
		// var values = Object.values(_customer);
		// for (let i = 0; i < keys.length; i++) {
		// 	var key: String = keys[i];
		// 	if(typeof(_customer[key]) === "string")
		// }
		setManex(true);
		_setCustomer(_customer);
		setCreate(true);
	}
	function viewCustUsers(cust: CustomerItem) {
		_setCustomer(cust);
		setUserView(true);
		if (xToken)
			fetchCustomerUsers(cust._id, xToken).then((res) => {
				setUsers(res.data);
			});
	}

	function demote_user(userID: string) {
		if (xToken)
			demoteUser(userID, xToken)
				.then((res) => {
					setUsers(users.filter((_user) => _user._id !== userID));
				})
				.catch((err) => alert(err.response.data));
	}
	function import_manex() {
		setShowManex(true);
		if (manexCustomers.length === 0 && xToken)
			setTimeout(() => {
				fetchManexCustomers(xToken).then((res) => {
					console.log("manex", res.data);
					setManexCustomers(res.data);
					setTimeout(() => {
						document.getElementById("mansearch")?.focus();
					}, 400);
				});
			}, 1000);
		else
			setTimeout(() => {
				let search = document.getElementById("mansearch") as HTMLInputElement;
				if (!search) return;
				search.value = "";
				search.focus();
			}, 400);
	}
	const [creating, setCreating] = useState<boolean>(false);
	function callUserCreation() {
		var username = document.getElementById("username") as HTMLInputElement;
		var email = document.getElementById("email") as HTMLInputElement;
		var firstName = document.getElementById("firstName") as HTMLInputElement;
		var lastName = document.getElementById("lastName") as HTMLInputElement;
		var title = document.getElementById("title") as HTMLInputElement;
		if (String(username.value).trim() === "") return username.focus();
		if (String(email.value).trim() === "") return email.focus();
		const _user = {
			username: (document.getElementById("username") as HTMLInputElement).value,
			email: (document.getElementById("email") as HTMLInputElement).value,
			firstName: (document.getElementById("firstName") as HTMLInputElement).value,
			lastName: (document.getElementById("lastName") as HTMLInputElement).value,
			title: (document.getElementById("title") as HTMLInputElement).value,
			phone: (document.getElementById("phone") as HTMLInputElement).value,
			company_id: _customer?._id || "",
			invite:
				(document.getElementById("autoemail") as HTMLInputElement)?.checked ||
				false,
		};
		if (!xToken) return;
		setCreating(true);
		setTimeout(() => {
			createUser(_user, false, xToken)
				.then((res) => {
					var _users = [...users];
					_users.push(res.data);
					setUsers(_users);
					setNewUser(false);
					setCreating(false);
					setToast(
						_user.invite
							? `Sent invite link to '${(res.data as UserData).email}'`
							: `Created user with email '${(res.data as UserData).email}'`
					);
				})
				.catch((err) => {
					alert(err.response.data);
					setCreating(false);
				});
		}, 600);
	}
	function start_creation() {
		setNewUser(true);
		setTimeout(() => {
			let field = document.getElementById("username");
			if (field) field.focus();
		}, 400);
	}

	const handleClickOnEditCustomer = async (e:React.MouseEvent<HTMLButtonElement>, cust:CustomerItem) => {

		e.preventDefault();

		try {

			if (!xToken) throw new Error('Token has expired');

			const res = await fetchContacts(xToken, cust._id)

			if (res.data.fromManex) {
				setContacts(res.data.contacts)
				_setCustomer(cust);
				setCreate(true)
				setContactsFromManex(true)
				return
			}

			setContacts(res.data.contacts)
			_setCustomer(cust);
			setCreate(true);
			setContactsFromManex(false)

		} catch (err) {

			console.log(err)
			alert('Something went wrong while fetching contacts.')

		}
		
	}

	return (
		<>
			<h2>Active Customers ({customers.length})</h2>
			<div>
				<Stack direction="vertical" gap={2}>
					<Stack
						direction="horizontal"
						gap={1}
						style={{ justifyContent: "center" }}
					>
						<Button
							variant="outline-primary"
							onClick={() => {
								_setCustomer(null);
								setCreate(true);
							}}
						>
							<i className="fa-solid fa-plus"></i> New Customer
						</Button>
						or
						<Button variant="warning" onClick={import_manex}>
							Import from manex
						</Button>
					</Stack>
					<InputGroup className="mt-2 mb-2">
						<InputGroup.Text>
							<i className="fa-solid fa-magnifying-glass"></i>
						</InputGroup.Text>
						<Form.Control
							type="text"
							placeholder={"Search for customer..."}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<Dropdown autoClose={"outside"}>
							<Dropdown.Toggle variant="secondary">
								<i className="fa-solid fa-filter"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu style={{padding : '10px'}}>
								{/* <Dropdown.Item
									onClick={() => setCustEnableToggle(!custEnableToggle)}
								> */}
									<Form.Check
										type="checkbox"
										label="Hide disabled"
										id="custEnabled"
										className="mt-2"
										onChange={() => setCustEnableToggle(!custEnableToggle)}
										checked={custEnableToggle}
									/>
								{/* </Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setHideServiceAgreementUploaded(!hideServiceAgreementUploaded)
									}}
								> */}
									<Form.Check
										type="checkbox"
										label="Hide valid S/A uploaded"
										id="noSA"
										className="mt-2"
										onChange={() => setHideServiceAgreementUploaded(!hideServiceAgreementUploaded)}
										checked={hideServiceAgreementUploaded}
									/>

								{/* </Dropdown.Item> */}
							</Dropdown.Menu>
						</Dropdown>
					</InputGroup>
				</Stack>
				<Accordion alwaysOpen>
					{customers
						.filter((a) =>
							a.supName.toLowerCase().includes(search.toLowerCase())
						)
						.filter(
							(a) => (custEnableToggle && a.verified) || !custEnableToggle
						)
						.filter(
							(a) => (hideServiceAgreementUploaded && ("serviceAgreement" in a == false || a.serviceAgreement == "" || a.serviceAgreementEndDate == undefined || (a.serviceAgreementEndDate != undefined && new Date() > new Date(a.serviceAgreementEndDate)))) || !hideServiceAgreementUploaded
						)
						.sort((a, b) => {
							return a.supName > b.supName ? 1 : -1;
						})
						.map((cust: CustomerItem, i) => {
							return (
								<Accordion.Item eventKey={i.toString()}>
									<Accordion.Header>
										<Stack direction="horizontal" style={{ width: "100%" }}>
											<span style={{ flex: "100%" }}>{cust.supName} <Badge bg="dark">{cust.manexCustomerId}</Badge> {hasValidServiceAgreement(cust) ? (<i className="fa-solid fa-star" style={{color: "#fede01"}}></i>) : ''}</span>
										</Stack>
									</Accordion.Header>
									<Accordion.Body>
										<Stack direction="horizontal" gap={1} className="mb-2">
											<Form.Check
												checked={cust.verified}
												id={"sortEnable" + cust._id}
												type="switch"
												onChange={(e) => {
													handleVerify(cust._id, e);
												}}
											></Form.Check>
											<Form.Label
												htmlFor={"sortEnable" + cust._id}
												className="mb-0"
											>
												Allow new sorts
											</Form.Label>
										</Stack>
										<Stack direction="vertical" gap={1}>
											<Stack direction="horizontal" gap={1}>
												<Button
													onClick={() => viewCustUsers(cust)}
													variant="dark"
													style={{ flex: "50%" }}
												>
													<i className="fa-solid fa-user-group"></i> Users
												</Button>
												<Link to={"/sorts/" + cust._id} style={{ flex: "50%" }}>
													<Button style={{ width: "100%" }}>
														<i className="fa-solid fa-arrow-up-right-from-square"></i>{" "}
														Sorts
													</Button>
												</Link>
											</Stack>
											<Stack direction="horizontal" gap={1}
											>
												<Link to={"/sorts-pos/" + cust._id} style={{ flex: "50%" }}>
													<Button style={{ width: "100%" }} variant="light">
														<i className="fa-solid fa-arrow-up-right-from-square"></i>{" "}
														Sorts & POs
													</Button>
												</Link>
												<Button
													onClick={(e) => handleClickOnEditCustomer(e, cust)}
													variant="secondary"
													style={{ width: "50%" }}
												>
													<i className="fa-solid fa-pen-to-square"></i> Edit
												</Button>
											</Stack>

											{cust.sa && cust.sa.length > 0 && (
												<Button
													onClick={() => {
														if (cust.sa !== undefined){
															_setCustomer(cust)
															setServiceAgreements(cust.sa)
															setShowServiceAgreements(true)
														}
													}}
													variant="info"
												>
													Service Agreements
												</Button>
											)}
											{(!cust.hasOwnProperty('manexCustomerId') || cust.manexCustomerId === "") && (
												<Button
													onClick={async (e) => {
														e.preventDefault();
														if (manexCustomers.length !== 0) {
															_setCustomer(cust);
															setCreate2(true);
														} else if (xToken) {
															try {
																const res = await fetchManexCustomers(xToken)
																console.log("manex", res.data);
																setManexCustomers(res.data);
																_setCustomer(cust);
																setCreate2(true);
															} catch (error) {
																alert(error)
															}
														}
													}}
													variant="success"
													style={{ width: "100%" }}
												>
													Link to Manex Customer
												</Button>
											)}
										</Stack>
									</Accordion.Body>
								</Accordion.Item>
							);
						})}
				</Accordion>
			</div>
			<ToastContainer
				style={{
					position: "fixed",
					bottom: "0",
					left: "50%",
					transform: "translateX(-50%)",
				}}
				position="bottom-center"
			>
				<Toast
					bg="success"
					onClose={() => setToast("")}
					show={toast !== ""}
					delay={4000}
					autohide
				>
					<Toast.Header closeButton={false}>
						<i className="fa-solid fa-check"></i> Success
					</Toast.Header>
					<Toast.Body style={{ color: "#fff" }}>{toast}</Toast.Body>
				</Toast>
			</ToastContainer>
			<Modal show={showManex} onHide={() => setShowManex(false)}>
				<Modal.Header>
					<h3>Add manex customer to portal</h3>
				</Modal.Header>
				<Modal.Body>
					{manexCustomers.length === 0 ? (
						<p style={{ textAlign: "center" }} className="mb-1">
							<span className="h4 mr-2">
								<i className="fa-solid fa-database blinky"></i>
							</span>{" "}
							Fetching data......
						</p>
					) : (
						<>
							<InputGroup className="mb-2">
								<InputGroup.Text>
									<i className="fa-solid fa-magnifying-glass"></i>
								</InputGroup.Text>
								<Form.Control
									type="text"
									placeholder="Search for customer..."
									value={manexSearch}
									id="mansearch"
									onChange={(e) => setManexSearch(e.target.value)}
								/>
							</InputGroup>
							<div style={{ maxHeight: 400, overflow: "scroll" }}>
								<Table striped bordered hover size="sm">
									<tbody>
										{Object.entries(manexCustomers).length > 0 &&
											manexCustomers
												.filter((a) =>
													a.CUSTNAME.toLowerCase().includes(
														manexSearch.toLowerCase()
													)
												)
												.sort((a, b) => {
													return a.CUSTNAME > b.CUSTNAME ? 1 : -1;
												})
												.map((mancust, i) => {
													return (
														<tr key={i}>
															<th>{mancust.CUSTNAME} <Badge bg="dark">{mancust.CUSTNO[0]}</Badge></th>
															<th style={{ textAlign: "right" }}>
																<Button
																	variant="success"
																	onClick={() => populate_customer(mancust)}
																>
																	<i className="fa-solid fa-plus"></i>
																</Button>
															</th>
														</tr>
													);
												})}
									</tbody>
								</Table>
							</div>
						</>
					)}
				</Modal.Body>
			</Modal>
			<Modal show={userView} onHide={handleClose} fullscreen={true}>
				<Modal.Header closeButton>
					<h3>{_customer?.supName}</h3>
				</Modal.Header>
				<Modal.Body>
					<Button
						variant="outline-primary"
						onClick={start_creation}
						className="mb-2"
					>
						<i className="fa-solid fa-user-plus"></i> New User
					</Button>
					<Table striped bordered hover size="sm">
						<thead>
							<tr>
								<th>User</th>
								<th>Email</th>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Title</th>
								<th>Phone</th>
								<th>Unjoin</th>
							</tr>
						</thead>
						<tbody>
							{users.map((_user: UserData, i: Key) => {
								return (
									<tr key={i}>
										<th>{_user.username}</th>
										<th>
											<a href={"mailto:" + _user.email}>{_user.email}</a>
										</th>
										<th>{_user.firstName}</th>
										<th>{_user.lastName}</th>
										<th>{_user.title}</th>
										<th>{_user.phone}</th>
										<th>
											{_user._id !== user?._id && (
												<>
													<Button
														variant="warning"
														onClick={() => demote_user(_user._id)}
													>
														<i className="fa-solid fa-user-minus"></i>
													</Button>
												</>
											)}
										</th>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
			<Modal show={showServiceAgreements} onHide={handleClose} fullscreen={true}>
				<Modal.Header closeButton>
					<h3>{_customer?.supName}</h3>
				</Modal.Header>
				<Modal.Body>
					<Table striped bordered hover size="sm">
						<thead>
							<tr>
								<th>Service Agreement</th>
								<th>Start Date</th>
								<th>End Date</th>
								<th>Technician Rate</th>
								<th>Supervisor Rate</th>
							</tr>
						</thead>
						<tbody>
							{serviceAgreements.map((sa: PcsServiceAgreement, i: Key) => {
								return (
									<tr key={i}>
										<th>
											<a 
												href={`https://gsassembly-pcs.com/GSA-PCS/backend/web/Uploads/service_agreements/${sa.file}`}
												target="_blank" rel="noopener noreferrer"
											>
												view
											</a>
										</th>
										<th>{sa.start_date.split('T')[0]}</th>
										<th>{sa.end_date.split('T')[0]}</th>
										<th>{sa.regular_rate}</th>
										<th>{sa.supervisor_rate}</th>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
			<Modal show={create} onHide={handleClose} fullscreen={true}>
				<Modal.Header closeButton>
					<Modal.Title>Provide customer info</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/*@ts-ignore */}
					<CustomerInfoForm
						customer={_customer}
						contacts={contacts}
						contactsFromManex={contactsFromManex}
						onSubmit={() => handleClose(true)}
						onCreate={show_new_customer}
						onUpdate={update_customer}
						onDelete={handleDelete}
						manex={manex}
					/>
				</Modal.Body>
			</Modal>
			{_customer && (
				<Modal show={create2} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Link to Manex Customer</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{/*@ts-ignore */}
						<LinkManexCustForm
							customer={_customer}
							manexCustomers={manexCustomers}
							handleSuccessfulAssociation={(cid, mcid) => {
								//update customers
								const newCustomers = customers.map((cust) => {
									if (cust._id === cid) {
										cust['manexCustomerId'] = mcid
									}
									return cust
								})
								if (setCustomers) setCustomers(newCustomers);
								handleClose()
							}}
						/>
					</Modal.Body>
				</Modal>
			)}
			<Modal show={newUser} onHide={() => setNewUser(false)}>
				<Modal.Body>
					<Form className="mb-2">
						<Form.Group className="mb-3" controlId="username">
							<Form.Label className="freq">Username</Form.Label>
							<Form.Control type="text" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="email">
							<Form.Label className="freq">Email</Form.Label>
							<Form.Control type="email" placeholder="user@email.com" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="firstName">
							<Form.Label className="">First Name</Form.Label>
							<Form.Control type="text" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="lastName">
							<Form.Label className="">Last Name</Form.Label>
							<Form.Control type="text" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="title">
							<Form.Label className="">Title</Form.Label>
							<Form.Control type="text" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="phone">
							<Form.Label className="">Phone</Form.Label>
							<Form.Control type="text" />
						</Form.Group>
						<Form.Check
							label={
								<p>
									Send invitation email <i className="fa-solid fa-envelope" />
								</p>
							}
							id="autoemail"
							defaultChecked
						/>
					</Form>
					<Stack direction="horizontal" gap={1}>
						<Button
							variant="primary"
							onClick={callUserCreation}
							disabled={creating}
							className="btn-lg"
						>
							Create
						</Button>
						{creating && <Spinner animation="border" />}
					</Stack>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default CustomersPage;
