import { useContext, useEffect, useState } from "react";
import {
	Alert,
	Button,
	Card,
	Container,
	Row,
	Col,
	Form,
	InputGroup,
	Stack,
	Tab,
	Tabs,
	Table,
	Modal
} from "react-bootstrap";
import { FormProvider, useForm, useFormContext, Controller } from "react-hook-form";
import React from "react";
import {
	createSupplier,
	SupplierContext,
	deleteSupplier,
	updateCustomer,
	apiUrl,
	createNewContact,
	updateContact
} from "../services/supService";
import { UserContext, xTokenContext } from "../services/authService";
import { useNavigate } from "react-router-dom";
import { CreateContactModal } from '../components/CreateContactModal'
import { EditContactModal } from "../components/EditContactModal";


export default function SupplierInfoForm(props) {
	const { xToken, setXToken } = useContext(xTokenContext);
	const { user, setUser } = useContext(UserContext);
	const { supplier, setSupplier } = useContext(SupplierContext);

	const [key, setKey] = useState("supplier");

	const [createContact, setCreateContact] = useState(false)
	const [editContact, setEditContact] = useState(false)
	const [contacts, setContacts] = useState([])
	const [contactGettingCreated, setContactGettingCreated] = useState({
		FIRSTNAME: '',
		LASTNAME: '',
		EMAIL: '',
		TITLE: '',
		WORKPHONE: '',
		DEPARTMENT: '',
		CONTNOTE: ''
	})
	const [contactGettingEdited, setContactGettingEdited] = useState({})

	const navigate = useNavigate();

	const methods = useForm();
	const register = methods.register;

	useEffect(() => {
		var _form = props.supplier;
		if (!_form) return;
		console.log("render supplier", props.supplier);
		const keys = Object.keys(_form);
		const values = Object.values(_form);
		for (let i = 0; i < keys.length; i++) {
			//if (keys[i] !== 'serviceAgreement' ) {
				methods.setValue(keys[i], String(values[i]));
			//}
		}
		//methods.setValue("mustAlwaysProvideSortNum", props.supplier.mustAlwaysProvideSortNum)

		//setContacts(props.contacts)
	}, []);

	const [step, setStep] = useState(1);
	const onSubmit = (data) => {
		if (!user.admin) return navigate("/");
		// if (data.serviceAgreement.length > 0 && (data.serviceAgreementStartDate.length <= 0 || data.serviceAgreementEndDate.length <= 0)) {
		// 	alert('You need to provide start date and end date of service agreement')
		// 	return
		// }

		// should customer's sorts default to hourly PO?
		//const defaultHourly = document.getElementById("canHourly").checked;
		var formData = new FormData()
		const dataAsArray = Object.entries(data)
		dataAsArray.forEach(item => {
			formData.append(item[0], item[1]);
		})
		//formData.append('defaultHourly', defaultHourly)

		// form submission is from database, update doc in db
		//if (customer && !props.manex && props.customer) {
		if (!props.manex && props.supplier) {
			return updateCustomer(xToken, props.supplier._id, formData).then((res) => {
				props.onUpdate(res.data);
				props.onSubmit();
			});
		}
		// form submission is from manex list, add customer to database
		// createCustomer({ ...data, defaultHourly }, xToken).then((res) => {
		// 	console.log("created");
		// 	if (props.manex || !props.customer) props.onCreate(res.data);
		// });
		
		createSupplier(formData, xToken).then((res) => {
			console.log("created");
			if (props.manex || !props.supplier) props.onCreate(res.data);
		});
		return props.onSubmit();
	};

	function next(e) {
		if (Object.entries(methods.formState.errors).length > 0) setKey("supplier");
		//e.preventDefault();
	}
	// function deletePrompt() {
	// 	var confirm = window.confirm(
	// 		"Deleting this customer will disassosciate its users!"
	// 	);
	// 	if (!confirm) return;
	// 	if (xToken)
	// 		deleteCustomer(props.customer._id, xToken).then((res) => {
	// 			props.onDelete(props.customer._id);
	// 		});
	// }

	const tabs = ["supplier"];
	return (
		<div>
			<Form onSubmit={methods.handleSubmit(onSubmit)} style={{ padding: 10 }}>
				<FormProvider {...methods}>
					<Tabs activeKey={key} onSelect={(k) => setKey(k)}>
						<Tab
							eventKey="supplier"
							title={<p className="freq mb-0">Supplier</p>}
						>
							<SupplierSection />
						</Tab>
					</Tabs>
				</FormProvider>
				<hr />
				<Stack
					direction="horizontal"
					gap={2}
					style={{ justifyContent: "space-between" }}
				>
					{/* <Button size="lg" onClick={next}>Next</Button> */}
					{key !== 'contacts' && (
						<>
							<input
								type="submit"
								className="btn btn-block btn-lg btn-primary btn-success"
								value="Save"
								onClick={next}
							/>
							{props.manex && step === 1 && (
								<input
									type="submit"
									className="btn btn-block btn-lg btn-dark"
									value="Add Now"
								/>
							)}
							{/* {props.customer !== null && step === 1 && !props.manex && (
								<Button variant="danger" size="lg" onClick={deletePrompt}>
									<i className="fa-solid fa-trash"></i> Delete
								</Button>
							)} */}
						</>
					)}
				</Stack>
			</Form>
		</div>
	);
}

function SupplierSection() {
	const { register } = useFormContext();
	return (
		<>
			{/*<Alert variant="light" style={{ margin: 0 }}>
				Who is the customer?
			</Alert>*/}
			<Form.Control type="hidden" {...register("supId")} />
			<Form.Group className="mb-3" controlId="supName">
				<Form.Label className="freq">Supplier Name</Form.Label>
				<Form.Control
					type="name"
					{...register("supName", { required: true })}
				/>
			</Form.Group>
		</>
	);
}
