import { useState, useContext } from "react";
import {
	fetchCustomers,
	CustomerItem,
	ICustomer,
	verifyCustomer,
	ManexCustomer,
	fetchCustomerUsers,
	CustomersContext,
	CustomerContext,
	linkToManexCust
} from "../services/custService";
import {
	Alert,
	Button,
	Card,
	Form,
	InputGroup,
	Stack,
	Tab,
	Tabs,
	Table
} from "react-bootstrap";
import { UserContext, xTokenContext } from "../services/authService";

type Prop = {
	customer: CustomerItem, 
	manexCustomers: Array<any>, 
	handleSuccessfulAssociation: (cid: string, mcid: string) => void
}

export default function LinkManexCustForm ({ customer, manexCustomers, handleSuccessfulAssociation }: Prop) {

	const [searchStr, setSearchStr] = useState('')
	const { xToken, setXToken } = useContext(xTokenContext);

	const linkToManex = async (custName: string, custNo: string) => {
		const confirmed: boolean = window.confirm(`Are you sure of the following linking?\nCustomer: ${customer.supName}\nManex Customer: ${custName}`);
		if (confirmed && xToken) {
			try {
				await linkToManexCust(xToken, customer._id, custNo, custName)
				handleSuccessfulAssociation(customer._id, custNo)
			} catch (error: any) {
				alert(error.response.data)
			}
		} else {
			alert('token has expired')
		}
	}

    return (
		<>
			<h3>{customer.supName}</h3>
			<Form.Control
				type="text"
				placeholder="Search for customer..."
				value={searchStr}
				onChange={(e) => setSearchStr(e.target.value)}
			/>
			<Table>
				<tbody>
					{manexCustomers
						.filter((a) =>
							a.CUSTNAME.toLowerCase().includes(
								searchStr.toLowerCase()
							)
						)
						.sort((a, b) => {
							return a.CUSTNAME > b.CUSTNAME ? 1 : -1;
						})
						.map((mcust) => (
							<tr>
								<td>{mcust.CUSTNAME.trim()}</td>
								<td><Button onClick={() => linkToManex(mcust.CUSTNAME.trim(), mcust.CUSTNO[0])}>Associate</Button></td>
							</tr>
						))
					}
				</tbody>
			</Table>
		</>

    )
}