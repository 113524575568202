import axios from "axios";
import { createContext, Dispatch, SetStateAction } from "react";
import { serverDomain } from "../fSettings";

export const apiUrl = `${serverDomain}/api/sup`;

const auth = axios.create({ withCredentials: true });

export async function createSupplier(data: Object, token: string[]) {
	console.log("token", token);
	//console.log("token", data);
	/*const entriesIterator = data;
    for (const pair of entriesIterator) {
        console.log(pair[0] + 'd:', pair[1]);
    }*/
	return auth.post(`${apiUrl}/create`, data, {
		headers: { "x-token": token, 'content-type': 'multipart/form-data' },
	});
}

export type ManexSupplier = {
	SUPID: string;
	SUPNAME: string;
};

export interface PcsServiceAgreement {
	id: string;
	id_customer: string;
	file: string;
	start_date: string;
	end_date: string;
	regular_rate: string;
	supervisor_rate: string;
}

export type SupplierItem = {
	_id: string;
	supName: string;
	supId: string;
	verified: boolean;
	defaultHourly?: boolean;
	regularRate: number;
	supervisorRate: number;
	mustAlwaysProvideSortNum: boolean;
};

export interface ISuppliersContext {
	suppliers: SupplierItem[];
	setSuppliers?: Dispatch<SetStateAction<SupplierItem[]>>;
}

export const SuppliersContext = createContext<ISuppliersContext>({
	suppliers: [],
});


export interface ISupplier {
	supplier: SupplierItem | null;
	setSupplier?: Dispatch<SetStateAction<null>>;
}

export const SupplierContext = createContext<ISupplier>({ supplier: null });

export async function viewSupplier(id: String | string, token: string) {
	return auth.get(`${apiUrl}/view/${id}`, { headers: { "x-token": token } });
}
export async function deleteSupplier(id: string, token: string) {
	return auth.delete(`${apiUrl}/delete/${id}`, {
		headers: { "x-token": token },
	});
}
export async function fetchSuppliers(token: string) {
	console.log('llegando...');
	return auth.get(`${apiUrl}/fetch`, { headers: { "x-token": token } });
}
export async function fetchSupplierUsers(supid: string, token: string) {
	return auth.get(`${apiUrl}/users/${supid}`, {
		headers: { "x-token": token },
	});
}
export async function fetchServiceAgreements(customerID: string, token: string) {
	return auth.get(`${apiUrl}/service-agreements/${customerID}`, {
		headers: { "x-token": token },
	});
}
export async function fetchValidServiceAgreementFile(filename: string, token: string) {
	return auth.get(`${apiUrl}/service-agreement-file/${filename}`, {
		headers: { "x-token": token },
		responseType: "arraybuffer"
	});
}
export async function verifySupplier(
	token: string,
	customerID: String,
	verify: Boolean
) {
	return auth.put(
		`${apiUrl}/verify/${customerID}`,
		{ verify: verify },
		{ headers: { "x-token": token } }
	);
}

export async function updateCustomer(
	token: string,
	customerID: String,
	data: Object
) {
	return auth.post(
		`${apiUrl}/update/${customerID}`,
		data,
		{ headers: { "x-token": token, 'Content-Type': 'multipart/form-data' } }
	);
}

export async function createNewContact(
	token: string,
	customerId: String,
	data: Object
) {
	return auth.post(
		`${apiUrl}/createNewContact/${customerId}`,
		data,
		{ headers: { "x-token": token } }
	);
}

export async function updateContact(
	token: string,
	data: Object
) {
	return auth.post(
		`${apiUrl}/updateContact/`,
		data,
		{ headers: { "x-token": token } }
	);
}

export async function fetchContacts(
	token: string,
	customerId: String
) {
	return auth.get(
		`${apiUrl}/fetchContacts/${customerId}`,
		{ headers: { "x-token": token, 'Cache-Control': 'no-cache' } }
	);
}

export async function linkToManexCust(
	token: string,
	customerID: String,
	CUSTNO: String,
	custName: string
) {
	return auth.post(
		`${apiUrl}/link-manex-cust/${customerID}`,
		{ CUSTNO, custName },
		{ headers: { "x-token": token } }
	);
}

/*export function hasValidServiceAgreement (supplier: SupplierItem) {
	if (supplier.supId === undefined) return false;
	if (supplier.supId.length <= 0) return false;
	const now = new Date()
	const sa = supplier.supId.find(s => {
		return s.supId >0;
	})
	if (sa === undefined) return false;
	return true;
}*/

export type ManexCustomerContact = {
	CID: string;
	FIRSTNAME: string;
	LASTNAME: string;
	TITLE: string;
	EMAIL: string;
	WORKPHONE: string;
	DEPARTMENT: string;
	CONTNOTE: string;
};

export type PortalCustomerContact = {
	_id: string;
	customerId: string;
	FIRSTNAME: string;
	LASTNAME: string;
	TITLE?: string;
	EMAIL: string;
	WORKPHONE?: string;
	DEPARTMENT?: string;
	CONTNOTE?: string;
};

export type newCustomerContact = {
	FIRSTNAME: string;
	LASTNAME: string;
	TITLE?: string;
	EMAIL: string;
	WORKPHONE?: string;
	DEPARTMENT?: string;
	CONTNOTE?: string;
};