import { AxiosError } from "axios";
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Tooltip as ChartTooltip,
	Legend,
	LinearScale,
	Title
} from "chart.js";
import { Key, useContext, useEffect, useState } from "react";
import {
	Alert,
	Button,
	Dropdown,
	FloatingLabel,
	Form,
	InputGroup,
	Modal,
	Stack,
	Table,
	Toast,
	ToastContainer
} from "react-bootstrap";
import { Bar, Doughnut } from "react-chartjs-2";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { serverDomain } from "../fSettings";
import { UserContext, xTokenContext } from "../services/authService";
import { fetchSortSupplierPassdowns } from "../services/manexService";
import {
	approveSortSupplier,
	CableReject,
	downloadFile,
	fetchSortsSupplier,
	genSortReportSupplier,
	holdSortSupplier,
	HourPerWeekday,
	markSortForApprovalSupplier,
	PartNumber,
	PassDetail,
	Passdown,
	passdown,
	PassdownDetail,
	PcsSort,
	PurchaseOrder,
	SortedRejectedQty,
	SortItem,
	transferSortSupplier
} from "../services/sortService";
import {
	fetchSuppliers,
	SupplierContext,
	SupplierItem,
	SuppliersContext,
	verifySupplier,
	viewSupplier,
} from "../services/supService";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	ChartTooltip,
	Legend
);

function SortsSupplierPage() {
	ChartJS.register(
		ArcElement,
		ChartTooltip,
		CategoryScale,
		LinearScale,
		BarElement,
		Title
	);

	const { supplierID, sortNumber } = useParams();

	const { xToken, setXToken } = useContext(xTokenContext);
	const { user, setUser } = useContext(UserContext);
	const { supplier, setSupplier } = useContext(SupplierContext);
	const { suppliers, setSuppliers } = useContext(SuppliersContext);

	const navigate = useNavigate();

	const [create, setCreate] = useState(false);
	const [pos, setPos] = useState<PurchaseOrder[]>([]);
	const [search, setSearch] = useState<string>("");
	const [filter, setFilter] = useState<string>("ALL");
	const [sorts, setSorts] =  useState<PcsSort[]>([]); //useState<SortItem[]>([]);
	const [transSort, setTransferSort] = useState<PcsSort | null>(null);
	const [psorts, setPendingSorts] = useState<SortItem[]>([]);
	const [curSort, setSupSort] = useState<SortItem | null>(null);
	const [activekey, setActiveKey] = useState("0");

	const [view, setView] = useState<SortItem | null>(null);
	const [purchaseOrder, setPurchaseOrder] = useState<string>("null");
	const [defDate, setDefDate] = useState("");
	const [generating, setGenerating] = useState(false);
	const [passdowns, setPassdowns] = useState<Passdown[]>([]);
	const [toast, setToast] = useState("");
	const [toastError, setToastError] = useState("");

	const [passdowns2, setPassdowns2] = useState<passdown[]>([])
	const [passdownDetails, setPassdownDetails] = useState<PassdownDetail[]>([])

	const [sortedRejectedQty, setSortedRejectedQty] = useState<SortedRejectedQty[]>([])
	const [partnumberGraphLabels, setPartnumberGraphLabels] = useState<string[]>([])
	const [sortedQty, setSortedQty] = useState<Number[]>([])
	const [rejectedQty, setRejectedQty] = useState<Number[]>([])

	const [hoursPerWeekday, setHoursPerWeekday] = useState<HourPerWeekday[]>([])
	const [technicianHoursPerWeekday, setTechnicianhoursPerWeekday] = useState<number[]>([])
	const [supervisorHoursPerWeekday, setSupervisorHoursPerWeekday] = useState<number[]>([])

	type WeekTuple = {
		start: Date;
		end: Date;
	};
	const [pdWeeks, setPdWeeks] = useState<WeekTuple[]>([]);
	const [weekFilter, setWeekFilter] = useState<WeekTuple | null>(null);
	const [passdown, setPassdown] = useState<Passdown | null>(null);
	const [reject, setReject] = useState("");

	const [loading, setLoading] = useState<boolean>(false); // slow queries to sql server
	const [loadError, setLoadError] = useState<string>("");
	const [loadingSort, setLoadingSort] = useState<PcsSort | null>(null);

	const [curSupplier, setCurSupplier] = useState<SupplierItem | null>(null); // for /sorts/:customerID route
	useEffect(() => {
		let element = document.getElementById("sortEnabled") as HTMLInputElement;
		if (curSupplier && element) element.checked = curSupplier.verified;
	}, [curSupplier]);
	// new sort modal
	const [partNumbers, setPartNumbers] = useState<PartNumber[]>([]);
	const [fetchingSorts, setFetchingSorts] = useState<boolean>(true)

	const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

	const partNumberQtyData = {
		labels: partnumberGraphLabels,
		datasets: [
			{
				label: 'Sorted',
				data: sortedQty,
				backgroundColor: 'rgba(255, 99, 132, 0.5)'
			},
			{
				label: 'Rejected',
				data: rejectedQty,
				backgroundColor: 'rgba(53, 162, 235, 0.5)'
			}
		]
	}

	const partNumberQtyOptions = {
		responsive: true,
		plugins: {
			title: {
				display: true,
				text: 'Sorted/Rejected Quantities'
			},
			legend: {
				position: 'top' as const,
			},
		},
		scales: {
			y: {
				title: {
					display: true,
					text: "Pieces",
				},
			},
		},
	}

	const hoursPerWeekdayData = {
		labels: weekdays,
		datasets: [
			{
				label: 'Technician Hours',
				data: technicianHoursPerWeekday,
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
			{
				label: 'Supervisor Hours',
				data: supervisorHoursPerWeekday,
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		]
	}

	const hoursPerWeekdayOptions = {
		responsive: true,
		plugins: {
			title: {
				display: true,
				text: 'Hours Per Weekday'
			},
			legend: {
				position: 'top' as const,
			},
		},
		scales: {
			y: {
				title: {
					display: true,
					text: "Hours",
				},
			},
		},
	}

	const locations = [
		"GFremont",
		"T901Page",
		"TFactory",
		"GMH",
		"TLV-Atlantis",
		"TLV-Discovery",
		"TLV-DXR",
		"TLathrop",
		"Newark"
	];
	const [sortLocs, setSortLocs] = useState<string[]>([]);
	const [sortPOs, setSortPOs] = useState<string[]>([]);
	function pick_location(e: any) {
		if (e.target.value) setSortLocs([...sortLocs, e.target.value]);
		(document.getElementById("locSelect") as HTMLSelectElement).value = ""; // reset option
	}
	function unpick_location(location: string) {
		setSortLocs(sortLocs.filter((loc) => loc !== location));
	}
	function add_po() {
		let input = document.getElementById("newPO") as HTMLInputElement;
		if (input.value.trim() !== "") enter_po(input.value.trim());
		else return input.focus();
	}
	function pick_po(e: any) {
		let value = e.target.value;
		setSortPOs([...sortPOs, value]);
		(document.getElementById("purchaseOrder") as HTMLSelectElement).value = ""; // reset field
	}
	function enter_po(e: any) {
		let code = e.charCode || e.keyCode || null;
		let value = !code ? e : null;
		if (code === 13 && !value) {
			let input = document.getElementById("newPO") as HTMLInputElement;
			value = input.value;
			if (value === "") return;
			setSortPOs([...sortPOs, value]);
			input.value = "";
			(document.getElementById("purchaseOrder") as HTMLInputElement).value = ""; // reset field
		} else if (value && value !== "") {
			let input = document.getElementById("newPO") as HTMLInputElement;
			input.value = "";
			setSortPOs([...sortPOs, value]);
			setTimeout(() => {
				document.getElementById("newPO")?.focus();
			}, 300);
		}
	}
	function unpick_po(po: string) {
		setSortPOs(sortPOs.filter((_po) => _po !== po));
	}

	const {
		register,
		handleSubmit,
		getValues,
		watch,
		setValue,
		formState: { errors },
		reset,
	} = useForm();
	const date = new Date();

	function closeView() {
		setView(null);
	}
	function handleClose() {
		setActiveKey("0");
		setCreate(false);
		setSupSort(null);
		setSortLocs([]);
		setSortPOs([]);
		setFiles(null);
		setSfiles([]);
		setDirtyFiles(false);
		setPartNumbers([]);
		reset();
	}
	function transfer_sort() {
		let newSupplier = document.getElementById("transfCust") as HTMLInputElement;
		let spinner = document.getElementById("transspinner");
		if (!newSupplier || !xToken || !transSort || newSupplier.value === "")
			return;
		if (spinner) spinner.style.display = "block";
		setTimeout(() => {
			transferSortSupplier(newSupplier.value, transSort?.SortNumber, xToken).then(
				(res) => {
					setSorts(sorts.filter((s) => s.SortNumber !== transSort.SortNumber));
					setTransferSort(null);
					if (spinner) spinner.style.display = "none";
					navigate("/sortssupplier/" + newSupplier.value);
				}
			);
		}, 500);
	}
	// function finalize_files(sort: SortItem) {
	// 	if (!xToken || !files) return;

	// 	let spinner = document.getElementById("filespinner");
	// 	if (spinner) spinner.style.display = "block";
	// 	let formData = new FormData();
	// 	files.forEach((_file) => formData.append("files", _file));

	// 	return new Promise((resolve, reject) => {
	// 		setTimeout(() => {
	// 			uploadFiles(sort.sortNumber, formData, xToken)
	// 				.then((_res) => {
	// 					let _files = files;
	// 					if (spinner) spinner.style.display = "none";
	// 					//handleClose(); // hide sort creation view

	// 					let _sort = sort;
	// 					_sort.files = [..._sort.files, ...files.map((f) => f.name)];
	// 					// update the sort list state to reflect newly updated files in the UI (without refresh)
	// 					let sortIndex = (_sort.approved ? sorts : psorts).findIndex(
	// 						(s) => s.SortNumber === _sort.sortNumber
	// 					);
	// 					/*if (sortIndex !== -1) {
	// 						let _sorts = _sort.approved ? [...sorts] : [...psorts];
	// 						_sorts[sortIndex] = _sort;
	// 						if (_sort.approved) setSorts(_sorts);
	// 						else setPendingSorts(_sorts);
	// 					}*/

	// 					//setCurSort(_sort); // show newly created sort view
	// 					resolve("");
	// 					setTimeout(() => {
	// 						setHourlyReport(sort.hourly || false);
	// 						setSfiles(_sort.files);
	// 						setFiles(null);
	// 					}, 500);
	// 				})
	// 				.catch((err) => {
	// 					setToastError(err.response.data);
	// 				});
	// 		}, 1200);
	// 	});
	// }
	// function onSubmit(data: Object) {
	// 	// @ts-ignore
	// 	if (data.target) data = getValues();

	// 	//console.log(data)

	// 	const _data = {
	// 		...data,
	// 		enterprise: supplierID || user?.enterprise,

	// 		hourly: hourlyReport,
	// 		locations: sortLocs,
	// 		purchaseOrders: sortPOs,
	// 		partNumbers: poll_pns(),
	// 		relabel:
	// 			(document.getElementById("relYes") as HTMLInputElement)?.checked ||
	// 			false,
	// 		open: true,
	// 		supName: supplier?.supName || curSupplier?.supName,
	// 	};
	// 	 //console.log(_data)
	// 	// return
	// 	if (!xToken) return;

	// 	if (!curSort) {
    //         //console.log(_data);
    //         //console.log("llegando y enviando datos pi");
	// 		createSortSupplier(_data, supplierID || user?.enterprise || "", xToken)
	// 			.then((res) => {
	// 				var _sorts: SortItem[] = psorts;
	// 				// @ts-ignore
	// 				_sorts.push(res.data);
	// 				setPendingSorts(_sorts);
	// 				//setCfiles([]);

	// 				// @ts-ignore
	// 				setToast("Created sort " + res.data["sortNumber"]);

	// 				// upon sort creation, upload files from user input
	// 				if (files && files.length > 0) {
	// 					finalize_files(res.data as SortItem);
	// 					handleClose();
	// 				} else {
	// 					//if (user?.admin) setTimeout(() => setCurSort(res.data), 500);
	// 					handleClose();
	// 				}
	// 			})
	// 			.catch((err) => {
	// 				console.log(err);
	// 				setToastError(err.response.data);
	// 				if (String(err.response.data).toLowerCase().includes("sort"))
	// 					document.getElementById("sortNumber")?.focus();
	// 			});
	// 	} else {
	// 		//need changing
	// 		if (!user?.admin && curSort.submittedForApproval) {
	// 			setToastError(
	// 				"This sort is already submitted for approval and cannot be edited"
	// 			);
	// 			return;
	// 		}
	// 		if (
	// 			user?.admin ||
	// 			(!curSort.approved && !curSort.denied && !curSort.submittedForApproval)
	// 		) {
	// 			// console.log(curSort.sortNumber);
	// 			// console.log(_data)
	// 			// return
	// 			updateSort(curSort.sortNumber, _data, xToken)
	// 				.then((res) => {
	// 					const updated_sort = res.data as SortItem;
	// 					let _sorts: PcsSort[] = updated_sort.approved ? sorts : psorts;
	// 					const sortIndex = _sorts.findIndex(
	// 						(s) => s.sortNumber === curSort.sortNumber
	// 					);
	// 					_sorts[sortIndex] = updated_sort;
	// 					//updated_sort.approved ? setSorts(_sorts) : setPendingSorts(_sorts);
	// 					// setCfiles([]);
	// 					// setCurSort(updated_sort);

	// 					setToast("Saved sort " + updated_sort.sortNumber);
	// 					// upon sort creation, upload files from user input
	// 					if (files && files.length > 0) {
	// 						finalize_files(updated_sort as SortItem);
	// 						handleClose();
	// 						//?.then(() => handleClose());
	// 					} else {
	// 						handleClose();
	// 					}
	// 				})
	// 				.catch((e) => {
	// 					console.log(e.response.data);
	// 					setToastError(e.response.data);
	// 				});
	// 		} else {
	// 			// upon sort creation, upload files from user input
	// 			if (files && files.length > 0) {
	// 				finalize_files(curSort); //?.then(() => handleClose());
	// 			} else {
	// 				handleClose();
	// 			}
	// 		}
	// 	}
	// }
	function handleSubmitForApproval(sort: SortItem) {
		if (!xToken) {
			setToastError("Submittion for approval was not successfull");
			return;
		}
		markSortForApprovalSupplier(sort.sortNumber, xToken)
			.then((res) => {
				if (res.status >= 200 && res.status < 300) {
					const updated_sort = res.data as SortItem;
					let _sorts: SortItem[] = psorts;
					const sortIndex = psorts.findIndex(
						(s) => s.sortNumber === updated_sort.sortNumber
					);
					_sorts[sortIndex] = updated_sort;
					if (updated_sort.scarNumber === '' || 'scarNumber' in updated_sort === false) {
						alert('Sort has been submitted successfully but scar # is not set.')
					}
					setPendingSorts(_sorts);
					handleClose();
				} else {
					console.log(res.data);
				}
			})
			.catch((err) => setToastError(err.response.data));
	}
	function onViewSubmit() {
		if (!loadingSort) return;
		const _json = {
			start: (document.getElementById("start") as HTMLInputElement).value,
			end: (document.getElementById("end") as HTMLInputElement).value,
		};
		setLoadError("");
		setLoading(true);
		xToken && fetchSortSupplierPassdowns(loadingSort.SortNumber, xToken)
			.then((res) => {
				setLoading(false);
				setPassdowns(res.data);
				if (res.data && res.data.length > 0) setLoadingSort(null);
				setTimeout(() => {
					let heading = document.getElementById("sortHeading");
					if (heading) heading.scrollIntoView({ behavior: "smooth" });
				}, 800);
			})
			.catch((err) => console.log(err));
	}
	function speed_report(sort:PcsSort) {
		setLoadError("");
		setLoading(true);
		setLoadingSort(sort);
		xToken && fetchSortSupplierPassdowns(sort.SortNumber, xToken)
			.then((res) => {
				setLoading(false);
				setLoadingSort(null);
				setPassdowns2(res.data.passdowns)
				setPassdownDetails(res.data.passdownDetails);
				setSortedRejectedQty(res.data.qty)
				setHoursPerWeekday(res.data.hrs)
				console.log(res.data)
				closeView();
				setTimeout(() => {
					let heading = document.getElementById("sortHeading");
					if (heading) heading.scrollIntoView({ behavior: "smooth" });
				}, 800);
				if(res.data.passdowns.length <= 0){
					alert(`There's no passdown data for sort ${sort.SortNumber} yet.`)
					if (supplierID && user?.admin) {
						(document.getElementById("sortSupplier") as HTMLSelectElement).value = supplierID;
					}
					return
				}
			})
			.catch((err: AxiosError) => {
				setLoading(false);
				setLoadError(err.message);
				closeView();
			});
	}
	useEffect(() => {
		let labels: string[] = []
		let sorted: Number[] = []
		let rejected: Number[] = []
		sortedRejectedQty.forEach(pn => {
			labels.push(pn.p_partnumber)
			sorted.push(pn.qty)
			rejected.push(pn.qty_rj)
		})
		setPartnumberGraphLabels(labels)
		setSortedQty(sorted)
		setRejectedQty(rejected)
	}, [sortedRejectedQty])
	useEffect(() => {
		let techHrs: number[] = []
		let supHrs: number[] = []
		weekdays.forEach(weekday => {
			let r: number = 0
			let s: number = 0
			hoursPerWeekday.forEach(hrPerWeekday => {
				if(hrPerWeekday.weekday === weekday){
					if(hrPerWeekday.p_partnumber.includes('-R-GSA')){
						r += hrPerWeekday.total_hours
					} else {
						s += hrPerWeekday.total_hours
					}
				}
			})
			techHrs.push(r)
			supHrs.push(s)
		})
		setTechnicianhoursPerWeekday(techHrs)
		setSupervisorHoursPerWeekday(supHrs)
		console.log(techHrs)
	}, [hoursPerWeekday])
	useEffect(() => {
		const cust = suppliers.find((c) => c._id === supplierID);
		if (cust) document.title = cust.supName;
	}, [supplierID, suppliers]);
	useEffect(() => {
		if (create) {
			let defaultHourly = false;
			if (supplierID) {
				const cust = suppliers.find((c) => c._id === supplierID);
				if (cust) defaultHourly = cust.defaultHourly || false;
			} else defaultHourly = supplier?.defaultHourly || false;
			setHourlyReport(defaultHourly);
		}
	}, [create]);
	useEffect(() => {
		setHourData([]);
		setBarData([]);
		setPassdowns([]);
	}, [supplierID]);
	useEffect(() => {
		if (xToken) {
			if (setSuppliers && Object.entries(suppliers).length === 0 && user?.admin)
				fetchSuppliers(xToken).then((res) => setSuppliers(res.data));

			// fetch sorts after user and customer data is set
			if (user && (supplierID || user?.supid !== "") && curSupplier) {

				if ( sorts.length === 0
					|| sorts[0].SUPID !== (supplierID || user.supid))
				fetchSortsSupplier(supplierID || user?.supid || "", xToken)
						.then((sorts) => {
							const data = sorts.data;
							setSorts(data);
							//setPendingSorts(data.filter((x) => !x.approved));
							setFetchingSorts(false)
							setPassdowns([]);
						})
						.catch((e) => {
							setToastError(e.response.data);
						});

                        //setToastError("verify..."+sorts.length);
				/*if (
					Object.entries(pos).length === 0 ||
					pos[0].enterprise !== (supplierID || user.enterprise)
				)
					fetchPurchaseOrders(supplierID || user?.enterprise, xToken).then(
						(res) => {
							setPos(res.data);
						}
					);*/
			}
			if (
				(supplierID || user?.supid !== "") &&
				user &&
				(!curSupplier || curSupplier._id !== supplierID)
			) {
				if (!supplierID || supplierID === user.supid) {
					console.log("mira", supplier);

					return setCurSupplier(supplier);
				}
				viewSupplier(supplierID || user?.supid || "", xToken).then((res) => {
					setCurSupplier(res.data);
					console.log(res.data);
				});
			}
		}
	}, [xToken, user, supplierID, supplier, curSupplier]);
	useEffect(() => {
		if (view) {
			var date = new Date(String(view?.startDate)).toISOString().split("T")[0];
			setDefDate(date);
		}
	}, [view]);
	const [hourlyReport, setHourlyReport] = useState(false);
	useEffect(() => {
		if (curSupplier && create) {
			if (!hourlyReport) return;
			let regInput = document.getElementById("regRate") as HTMLInputElement;
			if (!regInput) return;
			regInput.value = curSupplier.regularRate.toString();
			(document.getElementById("supRate") as HTMLInputElement).value =
				curSupplier.supervisorRate.toString();
		}
	}, [create, curSupplier, hourlyReport]);
	useEffect(() => {
		if (sortNumber) {
			let sort = psorts.find((s) => s.sortNumber === sortNumber);
			if (sort) {
				setSupSort(sort)
				setSfiles(sort.files)
			} else {
				let sort = sorts.find((s) => s.SortNumber === sortNumber);
				if (sort) speed_report(sort);
			}
		}
	}, [psorts, sorts]);
	useEffect(() => {
		if (curSort) {
			setPartNumbers(curSort.partNumbers);
			(document.getElementById("sortDate") as HTMLInputElement).value =
				curSort.startDate
					? new Date(curSort.startDate).toISOString().split("T")[0]
					: "";
			(document.getElementById("sortNumber") as HTMLInputElement).value =
				curSort.sortNumber;
			setValue("sortNumber", curSort.sortNumber);
			// (document.getElementById("scarNumber") as HTMLInputElement).value =
			// 	curSort.scarNumber || "";
			setValue("scarNumber", curSort.scarNumber);
			if (curSort.supInternalNum) {
				setValue("supInternalNum", curSort.supInternalNum);
			}
			setValue("supInternalNum", curSort.supInternalNum);
			(document.getElementById("requestLoc") as HTMLInputElement).value =
				curSort.requestLocation || "";
			setValue("requestLocation", curSort.requestLocation);
			setSortLocs(curSort.locations || []);
			setSortPOs(curSort.purchaseOrders || []);
			if (user?.admin) {
				(document.getElementById("poDesc") as HTMLInputElement).value =
					curSort.poComments || "";
				(document.getElementById("sortDesc") as HTMLInputElement).value =
					curSort.sortDescription || "";
			}
			if (curSort.hourly) {
				setHourlyReport(true);
				if (user?.admin)
					setTimeout(() => {
						(document.getElementById("regRate") as HTMLInputElement).value =
							curSort.regularRate || "0";
						(document.getElementById("supRate") as HTMLInputElement).value =
							curSort.supervisorRate || "0";
					}, 400);
			} else setHourlyReport(false);
			if (curSort.cleanPoint)
				setValue("cleanPoint", new Date(curSort.cleanPoint).toISOString().split("T")[0]);
			setValue("nonconfCondition", curSort.nonconfCondition);
			setValue("headCount", curSort.headCount);
			// setValue("cycleTime", curSort.cycleTime);
			setValue("boxPieces", curSort.boxPieces);
			if (user?.admin) {
				if (curSort.relabel)
					(document.getElementById("relYes") as HTMLInputElement).checked =
						true;
				else
					(document.getElementById("relNo") as HTMLInputElement).checked = true;
			}

			// render correct quantities...
			setTimeout(() => {
				for (
					let i = 0;
					i < (curSort.partNumbers ? curSort.partNumbers.length : 0);
					i++
				) {
					const pn = curSort.partNumbers[i];
					(
						document.getElementById(
							"sortQty" + i.toString()
						) as HTMLInputElement
					).value = pn.revision;
				}
			}, 400);
		}
	}, [curSort]);
	function remove_pn() {
		var pns = [...partNumbers];
		pns.pop();
		setPartNumbers(pns);
	}
	function add_pn() {
		var pns = [...partNumbers];
		pns.push({ pn: "", revision: "" });
		setPartNumbers(pns);
		setTimeout(
			() => document.getElementById("sortPart" + (pns.length - 1))?.focus(),
			400
		);
	}
	function poll_pns() {
		var result: PartNumber[] = [];

		var pns = document.getElementsByClassName("pnfield");
		for (let i = 0; i < pns.length; i++) {
			const pn = pns[i];
			const partNumber = (pn.querySelector(".pnval") as HTMLInputElement).value;
			if (partNumber.trim() === "") continue;
			const revision = (pn.querySelector(".pnrev") as HTMLInputElement).value.trim();
			result.push({ pn: partNumber, revision });
		}

		return result;
	}
	function make_report() {
		const _curSort = sorts.find(
			(sort) => sort.SortNumber === passdowns2[0].SortNumber.toString()
		);
		setGenerating(true);
		const id = supplierID || supplier?._id

		xToken && id && genSortReportSupplier(
			passdowns2[0].SortNumber,
			passdownDetails,
			id,
			xToken
		)
			.then((res) => {
				console.log(res.data);
				const filename = res.data
				var url = serverDomain + `/api/sort/download/${filename}`;

				const link = document.createElement("a");
				link.href = url;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				setGenerating(false);
			})
			.catch((err: AxiosError) => {
				if(err.response !== undefined) alert(err.response.data);
				setGenerating(false);
			});
	}
	function approve_sort(approve: boolean) {
		if (xToken && curSort)
			approveSortSupplier(
				curSort?.sortNumber,
				approve,
				hourlyReport,
				xToken,
				sortLocs
			).then((res) => {
				console.log(res.data);
				var _psorts = [...psorts];
				setPendingSorts(
					_psorts.filter((x) => x.sortNumber !== curSort.sortNumber)
				);
				if (approve) {
					var _sorts = [...sorts];
					_sorts.push(res.data);
					setSorts(_sorts);
					setSupSort(res.data);
					setToast(`Approved sort ${curSort.sortNumber}`);
				}
				handleClose();
			});
	}
	// if hold is false, we are denying the sort
	function holdordeny_sort(hold: boolean) {
		let action = hold ? "holding" : "denying";
		let comment =
			window.prompt(
				`Enter reason for ${action} sort # ${curSort?.sortNumber}`
			) || "";

		if (xToken && curSort)
			holdSortSupplier(curSort?.sortNumber, hold, comment, xToken).then((res) => {
				let updatedSort = res.data as SortItem;
				let sortIndex = psorts.findIndex(
					(sort) => sort.sortNumber === updatedSort.sortNumber
				);
				let _psorts = [...psorts];
				_psorts[sortIndex] = updatedSort;
				setPendingSorts(_psorts);
				setSupSort(updatedSort);
				setToast(`${hold ? "Held" : "Denied"} sort ${updatedSort.sortNumber}`);
			});
	}
	const [addingPO, setAddingPO] = useState(false);

	const [cfiles, setSfiles] = useState<string[]>([]);
	const [files, setFiles] = useState<Array<File> | null>(null);
	const [dirtyFiles, setDirtyFiles] = useState(false);
	function handle_files(e: React.ChangeEvent<HTMLInputElement>) {
		let _files: File[] = [];
		if (files) _files = [...files];
		if (e.target.files)
			Array.from(e.target.files).forEach((_file) => {
				_files.push(_file);
				if (
					!["pdf", "pptx"].includes(
						_file.name.toLowerCase().slice(_file.name.lastIndexOf(".") + 1)
					) &&
					!dirtyFiles
				)
					setDirtyFiles(true);
			});
		setFiles(_files);
	}
	function remove_file(index: number) {
		if (!files) return;
		var _files = [...files];
		_files?.splice(index, 1);
		setFiles(_files);
	}
	function download_file(fileName: string) {
		if (curSort && xToken)
			downloadFile(curSort?.enterprise, curSort?.sortNumber, fileName, xToken)
				.then((res) => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
					link.remove();
				})
				.catch((err) => {
					setToastError(err.response.data);
				});
	}
	// function delete_file(fileName: string) {
	// 	let _confirm = window.confirm(`Permanently delete '${fileName}'?`);
	// 	if (!_confirm) return;
	// 	if (curSort && xToken)
	// 		deleteFile(curSort?.sortNumber, fileName, xToken)
	// 			.then((res) => {
	// 				// rerender to hide affected file from UI
	// 				let _files = [...cfiles].filter((f) => f !== fileName);
	// 				//setCfiles(_files);

	// 				let _sorts = curSort?.closed ? sorts : psorts;
	// 				let sortIndex = _sorts.findIndex(
	// 					(s) => s.SortNumber === curSort.sortNumber
	// 				);
	// 				if (sortIndex !== -1) {
	// 					curSort["files"] = _files;
	// 					_sorts[sortIndex] = curSort; // update key to clear deleted item from file list
	// 					//(curSort.approved ? setSorts : setPendingSorts)(_sorts);
	// 					curSort?.approved ? setSorts(_sorts) : setPendingSorts(_sorts);
	// 					console.log(_sorts);
	// 				}
	// 				handleClose();
	// 			})
	// 			.catch((err) => {
	// 				setToastError(err.response.data);
	// 			});
	// 	console.log(psorts);
	// }

	function handleVerify(supplierID: String, e: any) {
		const status = e.target.checked;
		console.log(e.target.checked, supplierID);
		if (xToken)
			verifySupplier(xToken, supplierID, e.target.checked)
				.then((res) => {
					let _custs = [...suppliers];
					let _custIndex = _custs.findIndex((c) => c._id === supplierID);
					_custs[_custIndex].verified = status;
					if (setSuppliers) setSuppliers(_custs);
					setToast(`Customer ${status ? "verified" : "unverified"}!`);
				})
				.catch((err) => {
					setToastError("Please refresh page and try again.");
				});
	}
	const barOptions = {
		responsive: true,
		scales: {
			y: {
				title: {
					display: true,
					text: "Pieces",
				},
			},
		},
		plugins: {
			title: {
				display: true,
				text: "Part Number Summary",
			},
		},
	};
	const [barLabels, setBarLabels] = useState<string[]>([]);
	type PNDATA = {
		pass: number;
		rejects: number;
	};
	const [barData, setBarData] = useState<PNDATA[]>([]);

	const data = {
		labels: barLabels,
		datasets: [
			{
				label: "Pass",
				data: barData.map((d) => d.pass),
				backgroundColor: "rgba(0, 255, 0, 0.5)",
			},
			{
				label: "Reject",
				data: barData.map((d) => d.rejects),
				backgroundColor: "rgba(255, 0, 0, 0.5)",
			},
		],
	};

	const stackOptions = {
		plugins: {
			title: {
				display: true,
				text: "Work Week Summary",
			},
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
				title: {
					display: true,
					text: "Hours",
				},
			},
		},
	};
	type HRDATA = {
		regular: number;
		supervisor: number;
	};
	const [hourData, setHourData] = useState<HRDATA[]>([]);
	const stackLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	const sdata = {
		labels: stackLabels,
		datasets: [
			{
				label: "Regular",
				data: hourData.map((h) => h.regular),
				backgroundColor: "rgba(241, 196, 15, 0.5)",
			},
			{
				label: "Supervisor",
				data: hourData.map((h) => h.supervisor),
				backgroundColor: "rgba(211, 84, 0 ,0.5)",
			},
		],
	};
	useEffect(() => {
		if (Object.entries(passdowns).length > 0) {
			let reg_bals = new Array(7);
			let sup_bals = new Array(7);
			reg_bals.fill(0);
			sup_bals.fill(0);

			let pass_bals: { [id: string]: number } = {};
			let rej_bals: { [id: string]: number } = {};

			let pns: PNDATA[] = [];

			let selectWeeks: { start: Date; end: Date }[] = []; // set pdweeks state to this var

			// let curDay = -1; // index used to aggregate sort work weeks
			for (let p = 0; p < passdowns.length; p++) {
				const pd = passdowns[p];

				let workDate = new Date(pd.p_date);
				let workDay = workDate.getUTCDay(); // UTC is important..

				if (workDay !== 0) {
					if (
						selectWeeks.length > 0 &&
						selectWeeks[selectWeeks.length - 1].end > workDate
					)
						continue;
					// find the sunday of current week
					let temp = workDate;
					temp.setHours(0, 0, 0, 0);
					while (temp.getDay() > 0) temp.setDate(temp.getDate() - 1);
					let sat = new Date(temp.getTime());
					sat.setDate(sat.getDate() + 6);
					// sat.setHours(24);
					let week: WeekTuple = { start: temp, end: sat };
					selectWeeks.push(week);
				} else {
					let temp = workDate;
					temp.setHours(0, 0, 0, 0);
					let sat = new Date(temp.getTime());
					sat.setDate(sat.getDate() + 6);
					let week: WeekTuple = { start: temp, end: sat };
					selectWeeks.push(week);
				}
				// if (curDay === -1) curDay = workDay;
				// else if (curDay === workDay || workDay < curDay) {
				//   // new week
				// }
				// // else if()

				let multiplier = 1.0;
				// if(workDay === 0) multiplier = 2.0;
				// else if(workDay === 6) multiplier = 1.5;
				for (let i = 0; i < pd.detail.length; i++) {
					const pn = pd.detail[i];
					if (pn.p_partnumber.endsWith("-GSA")) {
						if (pn.p_partnumber.includes("-S-"))
							sup_bals[workDay] += pn.p_total_hours / multiplier;
						else reg_bals[workDay] += pn.p_total_hours / multiplier;
						continue;
					}
					if (pass_bals[pn.p_partnumber])
						pass_bals[pn.p_partnumber] += pn.p_qty - pn.p_qty_rj;
					else pass_bals[pn.p_partnumber] = pn.p_qty - pn.p_qty_rj;
					if (rej_bals[pn.p_partnumber])
						rej_bals[pn.p_partnumber] += pn.p_qty_rj;
					else rej_bals[pn.p_partnumber] = pn.p_qty_rj;
				}
			}
			setPdWeeks(selectWeeks);

			// hotfix for missing bar graph data
			setTimeout(() => {
				setWeekFilter(selectWeeks[0]);
				setTimeout(() => {
					setWeekFilter(null);
				}, 200);
			}, 400);

			setBarData(
				Object.entries(pass_bals).map((pd, i) => {
					return { pass: pd[1], rejects: rej_bals[pd[0]] };
				})
			);
			setHourData(
				stackLabels.map((_, i) => {
					return { regular: reg_bals[i], supervisor: sup_bals[i] };
				})
			);
			// interpret totals into graph
			setBarLabels(Object.keys(pass_bals).map((k) => k));
		}
	}, [passdowns]);
	useEffect(() => {
		if (Object.entries(passdowns).length > 0) {
			let reg_bals = new Array(7);
			let sup_bals = new Array(7);
			reg_bals.fill(0);
			sup_bals.fill(0);

			let pass_bals: { [id: string]: number } = {};
			let rej_bals: { [id: string]: number } = {};

			let pns: PNDATA[] = [];

			let pds = passdowns.filter(
				(pd) =>
					!weekFilter ||
					(new Date(pd.p_date) >= weekFilter.start &&
						new Date(pd.p_date) < weekFilter.end)
			);
			for (let p = 0; p < pds.length; p++) {
				const pd = pds[p];

				let workDate = new Date(pd.p_date);
				let workDay = workDate.getUTCDay(); // UTC is important..
				let multiplier = 1.0;
				// if(workDay === 0) multiplier = 2.0;
				// else if(workDay === 6) multiplier = 1.5;

				for (let i = 0; i < pd.detail.length; i++) {
					const pn = pd.detail[i];
					if (pn.p_partnumber.endsWith("-GSA")) {
						if (pn.p_partnumber.includes("-S-"))
							sup_bals[workDay] += pn.p_total_hours / multiplier;
						else reg_bals[workDay] += pn.p_total_hours / multiplier;
						continue;
					}
					if (pass_bals[pn.p_partnumber])
						pass_bals[pn.p_partnumber] += pn.p_qty - pn.p_qty_rj;
					else pass_bals[pn.p_partnumber] = pn.p_qty - pn.p_qty_rj;
					if (rej_bals[pn.p_partnumber])
						rej_bals[pn.p_partnumber] += pn.p_qty_rj;
					else rej_bals[pn.p_partnumber] = pn.p_qty_rj;
				}
			}
			setBarData(
				Object.entries(pass_bals).map((pd, i) => {
					return { pass: pd[1], rejects: rej_bals[pd[0]] };
				})
			);
			setHourData(
				stackLabels.map((_, i) => {
					return { regular: reg_bals[i], supervisor: sup_bals[i] };
				})
			);
			// interpret totals into graph
			setBarLabels(Object.keys(pass_bals).map((k) => k));
		}
	}, [weekFilter]);
	function disableSortNumField() {
		//if creating, always enable
		if (!curSort) {
			return false;
		} else {
			if (user?.admin) {
				return false
			}

			if (curSort.submittedForApproval) {
				return true
			}

			if (!("gsaSortNum" in curSort) || curSort.gsaSortNum === undefined) {
				return true
			}

			if (curSort.sortNumber.trim() !== curSort.gsaSortNum.trim()) {
				return true
			}

			return false

		}
	}
	return (
		<>
			<div className="d-flex justify-content-center flex-column">
				<h1>Sort Management. </h1>
				{supplierID && user?.admin ? (
					<select
						name="enterprise"
						id="sortSupplier"
						onChange={(e) => navigate("/sortssupplier/" + e.target.value)}
						className="mb-3"
					>
						{suppliers
							.sort((a, b) => (a.supName < b.supName ? -1 : 1))
							.map((cust, i: Key) => {
								if (cust._id == supplierID) return <option value={cust._id} selected>{cust.supName}</option>;
								else return <option value={cust._id} >{cust.supName}</option>;
							})}
					</select>
				) : (
					supplier?.supName
				)}
				{!user?.supid && !supplierID && (
					<Alert variant="warning">
						<Alert.Heading>
							Your account must be linked to an enterprise. Request a referral
							link.
						</Alert.Heading>
					</Alert>
				)}
				{/*{user?.admin && curSupplier && (
					<Stack direction="horizontal" gap={1} className="mb-2">
						<Form.Check
							defaultChecked={curSupplier.verified}
							id={"sortEnabled"}
							type="switch"
							onChange={(e) => {
								handleVerify(curSupplier._id, e);
							}}
						></Form.Check>
						<Form.Label htmlFor={"sortEnabled"} className="mb-0">
							Allow new sorts
						</Form.Label>
					</Stack>
				)}
				{(user?.enterprise || user?.admin) && (
					<Button variant="outline-primary" onClick={() => setCreate(true)}>
						<i className="fa-solid fa-plus"></i> Create Sort
					</Button>
				)}*/}
				<hr></hr>
			</div>
			<div
				style={{
					display: "flex",
					flexFlow: "row",
					alignItems: "start",
					gap: 20,
				}}
				className="fakeGrid"
			>
				<div>
					<InputGroup className="mt-2 mb-2">
						<InputGroup.Text>
							<i className="fa-solid fa-magnifying-glass"></i>
						</InputGroup.Text>
						<Form.Control
							type="text"
							placeholder={"Search for a sort..."}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<Dropdown autoClose={"outside"}>
							<Dropdown.Toggle variant="secondarys">
								<i className="fa-solid fa-filter"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ padding: "10px" }}>
								{["ALL"].map((opt) => {
									return (
										<Form.Check
											type="radio"
											name="sortStatus"
											label={opt}
											id={"sort" + opt}
											className="mb-2"
											value={opt}
											onChange={(e) => setFilter(opt)}
											checked={filter === opt}
										/>
									);
								})}
							</Dropdown.Menu>
						</Dropdown>
					</InputGroup>
					{loadError && (
						<Alert
							variant="danger"
							onClick={() => {
								setLoadingSort(null);
								setLoadError("");
							}}
						>
							{loadError}. Please try again later.
						</Alert>
					)}
					{//user &&
						fetchingSorts ? (
							<>Loading...</>
						) : (user &&
							(sorts.length > 0  ? (
								<div>
									<Table
										striped
										bordered
										hover
										variant="dark"
										size="sm"
										className="extable"
									>
										<thead>
											<tr>
												<th>Sort #</th>
												<th>Date</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{
											sorts.filter((s) =>
												s.SortNumber
													.toLowerCase()
													.includes(search.toLowerCase())
											).map((sort, index) =>  {
												const [datePart] = sort.Date.split('T');
												const [year, month, day] = datePart.split('-');
												const formattedDate = `${day}/${month}/${year}`;
												return (
													<tr key={index}>
														<th style={{ fontWeight: "normal" }}>
															{sort.SortNumber}
														</th>
														<th style={{ fontWeight: "normal" }}>
															{formattedDate}
														</th>
														<th style={{ fontWeight: "normal" }}>

															<Button
																onClick={() => {
																	speed_report(sort);
																}}
																disabled={loading}
																style={{ flex: "50%" }}
															>
																{!loadingSort ||
																loadingSort.SortNumber !== sort.SortNumber ? (
																	<i className="fa-solid fa-eye"></i>
																) : (
																	<i className="fa-solid fa-spinner spinner"></i>
																)}
															</Button>
														</th>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</div>
							) : (
								<p style={{ margin: 0 }}>
									Supplier currently has no running sorts.
								</p>
							))
						)

						}
				</div>

				{!loading && loadingSort && !loadError && (
					<div style={{ textAlign: "center" }}>
						<h3 className="m-0">
							<i className="fa-solid fa-magnifying-glass"></i> No data found
						</h3>
						<p className="mb-2">Try expanding the date range...</p>
						<Stack direction="horizontal" gap={1} className="mb-2">
							<FloatingLabel
								label="Start Date"
								controlId="start"
								style={{ flex: "50%" }}
							>
								<Form.Control
									type="date"
									defaultValue={
										new Date(loadingSort.Date).toISOString().split("T")[0]
									}
								/>
							</FloatingLabel>
							<FloatingLabel
								label="End Date"
								controlId="end"
								style={{ flex: "50%" }}
							>
								<Form.Control
									type="date"
									defaultValue={date.toISOString().split("T")[0]}
								/>
							</FloatingLabel>
						</Stack>
						<Stack direction="horizontal" gap={1}>
							<Button
								onClick={onViewSubmit}
								style={{ flex: "50%" }}
								variant="dark"
							>
								<i className="fa-solid fa-rotate"></i> Search again
							</Button>
							<Button
								variant="secondary"
								style={{ flex: "50%" }}
								onClick={() => setLoadingSort(null)}
							>
								<i className="fa-solid fa-x"></i> Cancel
							</Button>
						</Stack>
					</div>
				)}
				{Object.entries(passdowns2).length > 0 && (
					<div>
						<h4 id="sortHeading" style={{ scrollMarginTop: 40 }}>
							{passdowns2[0].SortNumber}
						</h4>
						<p style={{ opacity: 0.8 }} className="mb-2">
							<b>{passdowns2.length}</b> total passdowns
						</p>
						<Stack
							gap={1}
							direction="vertical"
							style={{ alignItems: "center" }}
						>
							<Button
								onClick={make_report}
								variant="primary"
								style={{ marginRight: 5 }}
								disabled={generating}
							>
								{!generating ? (
									<>
										<i className="fa-solid fa-file-arrow-down"></i> Download
									</>
								) : (
									<>
										<i
											className="fa-solid fa-spinner spinner"
											style={{ fontSize: "1.5rem" }}
										></i>{" "}
										Generating...
									</>
								)}
							</Button>
							{/* <div id="toggleNest">
								<Form.Check type="checkbox" label="Fix hours" id="poActual" />
							</div> */}
						</Stack>
						<div
							style={{ maxHeight: 300, overflowY: "scroll" }}
							className="mb-3 mt-3"
						>
							<Table striped bordered hover size="sm">
								<thead>
									<tr>
										<th>Passdown</th>
										<th>Shift</th>
										<th>Date</th>
									</tr>
								</thead>
								<tbody>
									{passdowns2
										.sort((a, b) => (a.p_date < b.p_date ? -1 : 1))
										.map((passdown: passdown, i: Key) => {
											return (
												<tr>
													<td>{passdown.id_pass}</td>
													<td>{passdown.p_shift}</td>
													<td>{passdown.p_date.split('T')[0]}</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
						</div>
					</div>
				)}
				<ToastContainer
					style={{
						position: "fixed",
						bottom: "0",
						left: "50%",
						transform: "translateX(-50%)",
					}}
					position="bottom-center"
				>
					<Toast
						bg="success"
						onClose={() => setToast("")}
						show={toast !== ""}
						delay={4000}
						autohide
					>
						<Toast.Header closeButton={false}>
							<i className="fa-solid fa-check"></i> Success
						</Toast.Header>
						<Toast.Body style={{ color: "#fff" }}>{toast}</Toast.Body>
					</Toast>
					<Toast
						bg="danger"
						onClose={() => setToastError("")}
						show={toastError !== ""}
						delay={Math.min(Math.max(toastError.length * 80, 4000), 10000)}
						autohide
					>
						<Toast.Header closeButton={false}>
							<i className="fa-solid fa-xmark"></i> Action failed
						</Toast.Header>
						<Toast.Body style={{ color: "#fff" }}>{toastError}</Toast.Body>
					</Toast>
				</ToastContainer>
				<Modal
					show={passdown !== null}
					onHide={() => {
						setPassdown(null);
						setReject("");
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Passdown {passdown?.id_pass}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							<i
								className="fa-solid fa-stopwatch"
								style={{ fontSize: "1.5rem" }}
							></i>{" "}
							{String(passdown?.detail[0].p_total_hours)} regular hours and{" "}
							{String(passdown?.detail[1].p_total_hours)} supervisor hours
							<br />
							<b>Notes</b>: {passdown?.notes}
						</p>
						<hr style={{ width: "100%" }}></hr>
						{passdown?.detail
							.filter(
								(x) =>
									!x.p_partnumber.endsWith("-R-GSA") &&
									!x.p_partnumber.endsWith("-S-GSA")
							)
							.map((pnDetail) => {
								return (
									<>
										<PnCard passdown={pnDetail} />
										<hr style={{ width: "100%" }}></hr>
									</>
								);
							})}
						<img src={reject} style={{ maxWidth: "90%" }}></img>
					</Modal.Body>
				</Modal>
			</div>
			<div style={{ width: "clamp(250px, 80%, 600px)" }}>
				{sortedRejectedQty.length > 0 && (
					<>
						<Bar options={partNumberQtyOptions} data={partNumberQtyData} />
						<div style={{ overflowY: "scroll" }}>
							<Table striped bordered variant="dark" size="sm">
								<thead>
									<tr>
										<th>PN</th>
										<th>Total (Rejects)</th>
									</tr>
								</thead>
								<tbody>
									{sortedRejectedQty.map((pn) => {
										return (
											<tr>
												<th>{pn.p_partnumber}</th>
												<th>
													{pn.qty.toString()} ({pn.qty_rj.toString()})
												</th>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</div>
					</>

				)}
				{hoursPerWeekday.length > 0 && (
					<>
						<Bar options={hoursPerWeekdayOptions} data={hoursPerWeekdayData} />
						<div style={{ overflowY: "scroll",marginTop: "10px" }}>
							<Table striped bordered variant="dark" size="sm">
								<thead>
									<tr>
										<th>Weekday</th>
										<th>Technician Hours</th>
									</tr>
								</thead>
								<tbody>
									{weekdays.map((weekday, index) => {
										return (
										<tr>
											<td>{weekday}</td>
											<td>{technicianHoursPerWeekday[index]}</td>
										</tr>
									)})}
								</tbody>
							</Table>
						</div>
						<div style={{ overflowY: "scroll",marginTop: "10px" }}>
							<Table striped bordered variant="dark" size="sm">
								<thead>
									<tr>
										<th>Weekday</th>
										<th>Supervisor Hours</th>
									</tr>
								</thead>
								<tbody>
									{weekdays.map((weekday, index) => {
										return (
										<tr>
											<td>{weekday}</td>
											<td>{supervisorHoursPerWeekday[index]}</td>
										</tr>
									)})}
								</tbody>
							</Table>
						</div>
					</>

				)}
			</div>
		</>
	);
}

export default SortsSupplierPage;

const PnCard = ({ passdown: detail }: { passdown: PassDetail }) => {
	const doughData = {
		labels: ["Pass", "Reject"],
		datasets: [
			{
				label: "Part(s)",
				data:
					detail !== null
						? [(detail?.p_qty || 0) - detail?.p_qty_rj, detail?.p_qty_rj]
						: [],
				backgroundColor: ["#9edddd", "#ffa8ba"],
				borderWidth: 0,
			},
		],
	};
	return (
		<>
			<Stack direction="horizontal" gap={1}>
				<div>
					<p>
						PN: <b>{detail?.p_partnumber}</b>
						<br />
						Quantity (Rejects):{" "}
						<b>
							{String(detail?.p_qty)} (<b>{String(detail?.p_qty_rj)}</b>)
						</b>
						<br />
					</p>
				</div>
				<Stack direction="vertical" gap={1}>
					{/* <p style={{ textAlign: "center", margin: 0 }}>Rejects Summary</p> */}
					<Doughnut
						data={doughData}
						style={{
							maxWidth: "150px",
							maxHeight: "150px",
							margin: "auto",
						}}
						options={{ plugins: { legend: { display: false } } }}
					/>
				</Stack>
			</Stack>
			{detail.production.length > 0 && (
				<>
					<h4>Production</h4>
					<Table striped bordered hover variant="dark" size="sm">
						<tbody>
							{detail.production.map((cable) => {
								return (
									<tr>
										<th>
											<p style={{ margin: 0 }}>
												<i className="fa-solid fa-box"></i> {cable.SLP}
											</p>
										</th>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</>
			)}
			{detail.rejects.length > 0 && (
				<>
					<h4>
						<i className="fa-solid fa-xmark"></i> Rejects
					</h4>
					<Table striped bordered hover variant="dark" size="sm">
						<tbody>
							{detail?.rejects.map((reject: CableReject, i: Key) => {
								return (
									<tr>
										<th>
											<a href={reject.photo} target="_blank">
												<i className="fa-solid fa-box-open"></i> {reject.SLP}
											</a>
										</th>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</>
			)}
		</>
	);
};
