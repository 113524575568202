import { Key, useContext, useEffect, useState } from "react";
import {
	Accordion,
	Button,
	Badge,
	Dropdown,
	Form,
	InputGroup,
	Modal,
	Row,
	Spinner,
	Stack,
	Table,
	Toast,
	ToastContainer,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
	xTokenContext,
	UserData,
	UserContext,
	demoteUser,
	createUser,
} from "../services/authService";
import {
	fetchSuppliers,
	SupplierItem,
	ISupplier,
	verifySupplier,
	fetchSupplierUsers,
	SuppliersContext,
	SupplierContext,
	PcsServiceAgreement,
	fetchContacts,
	ManexSupplier
} from "../services/supService";
import { fetchManexSuppliers } from "../services/manexService";
import SupplierInfoForm from "./SupForm";
import LinkManexCustForm from "../components/LinkManexCustForm";
//import { SupplierItem } from "../services/supService";

function SuppliersPage() {
	const { user, setUser } = useContext(UserContext);
	const { xToken, setXToken } = useContext(xTokenContext);
	const { suppliers, setSuppliers } = useContext(SuppliersContext);
	const [custEnableToggle, setCustEnableToggle] = useState<boolean>(true);
	const [hideServiceAgreementUploaded, setHideServiceAgreementUploaded] = useState<boolean>(false);

	const [create, setCreate] = useState<boolean>(false);
	const [create2, setCreate2] = useState<boolean>(false);
	const [newUser, setNewUser] = useState<boolean>(false);
	const [toast, setToast] = useState("");

	const [manexSearch, setManexSearch] = useState<string>("");
	const [search, setSearch] = useState<string>("");
	const [manex, setManex] = useState<boolean>(false);
	const [showManex, setShowManex] = useState<boolean>(false);
	const [manexSuppliers, setManexSuppliers] = useState<ManexSupplier[]>([]);

	const { supplier, setSupplier } = useContext(SupplierContext); // user's assigned supplier
	const [_supplier, _setSupplier] = useState<SupplierItem | null>(null); // selected view supplier

	const [userView, setUserView] = useState<boolean>(false);
	const [users, setUsers] = useState<UserData[]>([]);
	const [serviceAgreements, setServiceAgreements] = useState<PcsServiceAgreement[]>([])
	const [showServiceAgreements, setShowServiceAgreements] = useState<boolean>(false)
	useEffect(() => {
		if (xToken && setSuppliers) {
			fetchSuppliers(xToken).then((res) => {
				console.log("suppliers ", res.data);
				setSuppliers(res.data)
			});
		}
	}, [xToken]);
	function handleClose(imported: boolean = false) {
		setCreate(false);
		//setContacts([])
		_setSupplier(null);
		//setContactsFromManex(null)

		setCreate2(false);
		setManex(false);
		setUserView(false);
		setShowServiceAgreements(false)
		if (imported) setShowManex(false);
	}
	function handleVerify(customerID: String, e: any) {
		const status = e.target.checked;
		console.log(e.target.checked, customerID);
		if (xToken)
			verifySupplier(xToken, customerID, e.target.checked)
				.then((res) => {
					console.log(res.data);
					let _custs = [...suppliers];
					let _custIndex = _custs.findIndex((c) => c._id === customerID);
					//_custs[_custIndex].verified = status;
					if (setSuppliers) setSuppliers(_custs);
					if (customerID === user?.enterprise && setSupplier)
						// @ts-ignore
						setCustomer(_custs[_custIndex]);
					setToast(`Customer ${status ? "verified" : "unverified"}!`);
				})
				.catch((err) => {
					alert("Please refresh page");
				});
	}

	function show_new_supplier(sup: SupplierItem) {
		var _supps = [...suppliers];
		_supps.push(sup);
		setToast(`Created supplier '${sup.supName}'`);
		if (setSuppliers) setSuppliers(_supps);
	}
	function populate_supplier(sup: ManexSupplier) {
		var _supplier: SupplierItem = {
			_id: "",
			supName: sup.SUPNAME,
			supId: sup.SUPID,
			verified: false,
			defaultHourly: true,
			regularRate: 0.00,
			supervisorRate: 0.00,
			mustAlwaysProvideSortNum: true
		};
		setManex(true);
		_setSupplier(_supplier);
		setCreate(true);
	}
	function viewCustUsers(sup: SupplierItem) {
		_setSupplier(sup);
		setUserView(true);
		if (xToken)
			fetchSupplierUsers(sup._id, xToken).then((res) => {
				setUsers(res.data);
			});
	}

	function demote_user(userID: string) {
		if (xToken)
			demoteUser(userID, xToken)
				.then((res) => {
					setUsers(users.filter((_user) => _user._id !== userID));
				})
				.catch((err) => alert(err.response.data));
	}
	function import_manex() {
		setShowManex(true);
		if (manexSuppliers.length === 0 && xToken)
			setTimeout(() => {
				fetchManexSuppliers(xToken).then((res) => {
					console.log("manex", res.data);
					setManexSuppliers(res.data);
					setTimeout(() => {
						document.getElementById("mansearch")?.focus();
					}, 400);
				});
			}, 1000);
		else
			setTimeout(() => {
				let search = document.getElementById("mansearch") as HTMLInputElement;
				if (!search) return;
				search.value = "";
				search.focus();
			}, 400);
	}
	const [creating, setCreating] = useState<boolean>(false);
	function callUserCreation() {
		var username_dom = document.getElementById("username") as HTMLInputElement;
		const username = String(username_dom.value).trim()
		if (username === "") return username_dom.focus();
		var email_dom = document.getElementById("email") as HTMLInputElement;
		var email = String(email_dom.value).trim()
		if (email === "") return email_dom.focus();
		var firstName_dom = document.getElementById("firstName") as HTMLInputElement;
		var lastName_dom = document.getElementById("lastName") as HTMLInputElement;
		var title_dom = document.getElementById("title") as HTMLInputElement;
		var phone_dom = document.getElementById("phone") as HTMLInputElement
		const _user = {
			username,
			email,
			firstName: firstName_dom.value,
			lastName: lastName_dom.value,
			title: title_dom.value,
			phone: phone_dom.value,
			company_id: _supplier?._id || "",
			invite:
				(document.getElementById("autoemail") as HTMLInputElement)?.checked ||
				false,
		};
		if (!xToken) return;
		setCreating(true);
		setTimeout(() => {
			createUser(_user, true, xToken)
				.then((res) => {
					var _users = [...users];
					_users.push(res.data);
					setUsers(_users);
					setNewUser(false);
					setCreating(false);
					setToast(
						_user.invite
							? `Sent invite link to '${(res.data as UserData).email}'`
							: `Created user with email '${(res.data as UserData).email}'`
					);
				})
				.catch((err) => {
					alert(err.response.data);
					setCreating(false);
				});
		}, 600);
	}
	function start_creation() {
		setNewUser(true);
		setTimeout(() => {
			let field = document.getElementById("username");
			if (field) field.focus();
		}, 400);
	}

	const handleClickOnEditCustomer = async (e: React.MouseEvent<HTMLButtonElement>, cust: SupplierItem) => {

		e.preventDefault();

		try {

			if (!xToken) throw new Error('Token has expired');

			const res = await fetchContacts(xToken, cust._id)

			if (res.data.fromManex) {
				//setContacts(res.data.contacts)
				_setSupplier(cust);
				setCreate(true)
				//setContactsFromManex(true)
				return
			}

			//setContacts(res.data.contacts)
			_setSupplier(cust);
			setCreate(true);
			//setContactsFromManex(false)

		} catch (err) {

			console.log(err)
			alert('Something went wrong while fetching contacts.')

		}

	}

	return (
		<>
			<h2>Active Suppliers ({suppliers.length})</h2>
			<div>
				<Stack direction="vertical" gap={2}>
					<Stack
						direction="horizontal"
						gap={1}
						style={{ justifyContent: "center" }}
					>
						{/*<Button
							variant="outline-primary"
							onClick={() => {
								_setCustomer(null);
								setCreate(true);
							}}
						>
							<i className="fa-solid fa-plus"></i> New Customer
						</Button>
						or*/}
						<Button variant="warning" onClick={import_manex}>
							Import from manex
						</Button>
					</Stack>
					<InputGroup className="mt-2 mb-2">
						<InputGroup.Text>
							<i className="fa-solid fa-magnifying-glass"></i>
						</InputGroup.Text>
						<Form.Control
							type="text"
							placeholder={"Search for suppliers..."}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<Dropdown autoClose={"outside"}>
							<Dropdown.Toggle variant="secondary">
								<i className="fa-solid fa-filter"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ padding: '10px' }}>
								{/* <Dropdown.Item
									onClick={() => setCustEnableToggle(!custEnableToggle)}
								> */}
								<Form.Check
									type="checkbox"
									label="Hide disabled"
									id="custEnabled"
									className="mt-2"
									onChange={() => setCustEnableToggle(!custEnableToggle)}
									checked={custEnableToggle}
								/>
								{/* </Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setHideServiceAgreementUploaded(!hideServiceAgreementUploaded)
									}}
								> */}
								<Form.Check
									type="checkbox"
									label="Hide valid S/A uploaded"
									id="noSA"
									className="mt-2"
									onChange={() => setHideServiceAgreementUploaded(!hideServiceAgreementUploaded)}
									checked={hideServiceAgreementUploaded}
								/>

								{/* </Dropdown.Item> */}
							</Dropdown.Menu>
						</Dropdown>
					</InputGroup>
				</Stack>
				<Accordion alwaysOpen>
					{suppliers
						.filter((a) =>
							a.supName.toLowerCase().includes(search.toLowerCase())
						)
						.sort((a, b) => {
							return a.supName > b.supName ? 1 : -1;
						})
						.map((sup: SupplierItem, i) => {
							return (
								<Accordion.Item eventKey={i.toString()}>
									<Accordion.Header>
										<Stack direction="horizontal" style={{ width: "100%" }}>
											<span style={{ flex: "100%" }}>{sup.supName} <Badge bg="dark">{sup.supId}</Badge> </span>
										</Stack>
									</Accordion.Header>
									<Accordion.Body>
										<Stack direction="horizontal" gap={1} className="mb-2">

										</Stack>
										<Stack direction="vertical" gap={1}>
											<Stack direction="horizontal" gap={1}>
												<Button
													onClick={() => viewCustUsers(sup)}
													variant="dark"
													style={{ flex: "50%" }}
												>
													<i className="fa-solid fa-user-group"></i> Users
												</Button>
												<Link to={"/sortssupplier/" + sup._id} style={{ flex: "50%" }}>
													<Button style={{ width: "100%" }}>
														<i className="fa-solid fa-arrow-up-right-from-square"></i>{" "}
														Sorts
													</Button>
												</Link>
											</Stack>
										</Stack>
									</Accordion.Body>
								</Accordion.Item>
							);
						})}
				</Accordion>
			</div>
			<ToastContainer
				style={{
					position: "fixed",
					bottom: "0",
					left: "50%",
					transform: "translateX(-50%)",
				}}
				position="bottom-center"
			>
				<Toast
					bg="success"
					onClose={() => setToast("")}
					show={toast !== ""}
					delay={4000}
					autohide
				>
					<Toast.Header closeButton={false}>
						<i className="fa-solid fa-check"></i> Success
					</Toast.Header>
					<Toast.Body style={{ color: "#fff" }}>{toast}</Toast.Body>
				</Toast>
			</ToastContainer>
			<Modal show={showManex} onHide={() => setShowManex(false)}>
				<Modal.Header>
					<h3>Add manex supplier to portal</h3>
				</Modal.Header>
				<Modal.Body>
					{manexSuppliers.length === 0 ? (
						<p style={{ textAlign: "center" }} className="mb-1">
							<span className="h4 mr-2">
								<i className="fa-solid fa-database blinky"></i>
							</span>{" "}
							Fetching data......
						</p>
					) : (
						<>
							<InputGroup className="mb-2">
								<InputGroup.Text>
									<i className="fa-solid fa-magnifying-glass"></i>
								</InputGroup.Text>
								<Form.Control
									type="text"
									placeholder="Search for supplier..."
									value={manexSearch}
									id="mansearch"
									onChange={(e) => setManexSearch(e.target.value)}
								/>
							</InputGroup>
							<div style={{ maxHeight: 400, overflow: "scroll" }}>
								<Table striped bordered hover size="sm">
									<tbody>
										{Object.entries(manexSuppliers).length > 0 &&
											manexSuppliers
												.filter((a) =>
													a.SUPNAME.toLowerCase().includes(
														manexSearch.toLowerCase()
													)
												)
												.sort((a, b) => {
													return a.SUPNAME > b.SUPNAME ? 1 : -1;
												})
												.map((mansup, i) => {
													return (
														<tr key={i}>
															<th>{mansup.SUPNAME} <Badge bg="dark">{mansup.SUPID}</Badge></th>
															<th style={{ textAlign: "right" }}>
																<Button
																	variant="success"
																	onClick={() => populate_supplier(mansup)}
																>
																	<i className="fa-solid fa-plus"></i>
																</Button>
															</th>
														</tr>
													);
												})}
									</tbody>
								</Table>
							</div>
						</>
					)}
				</Modal.Body>
			</Modal>
			<Modal show={userView} onHide={handleClose} fullscreen={true}>
				<Modal.Header closeButton>
					<h3>{_supplier?.supName}</h3>
				</Modal.Header>
				<Modal.Body>
					<Button
						variant="outline-primary"
						onClick={start_creation}
						className="mb-2"
					>
						<i className="fa-solid fa-user-plus"></i> New User
					</Button>
					<Table striped bordered hover size="sm">
						<thead>
							<tr>
								<th>User</th>
								<th>Email</th>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Title</th>
								<th>Phone</th>
								<th>Unjoin</th>
							</tr>
						</thead>
						<tbody>
							{users.map((_user: UserData, i: Key) => {
								return (
									<tr key={i}>
										<th>{_user.username}</th>
										<th>
											<a href={"mailto:" + _user.email}>{_user.email}</a>
										</th>
										<th>{_user.firstName}</th>
										<th>{_user.lastName}</th>
										<th>{_user.title}</th>
										<th>{_user.phone}</th>
										<th>
											{_user._id !== user?._id && (
												<>
													<Button
														variant="warning"
														onClick={() => demote_user(_user._id)}
													>
														<i className="fa-solid fa-user-minus"></i>
													</Button>
												</>
											)}
										</th>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
			<Modal show={showServiceAgreements} onHide={handleClose} fullscreen={true}>
				<Modal.Header closeButton>
					<h3>{_supplier?.supName}</h3>
				</Modal.Header>
				<Modal.Body>
					<Table striped bordered hover size="sm">
						<thead>
							<tr>
								<th>Service Agreement</th>
								<th>Start Date</th>
								<th>End Date</th>
								<th>Technician Rate</th>
								<th>Supervisor Rate</th>
							</tr>
						</thead>
						<tbody>
							{serviceAgreements.map((sa: PcsServiceAgreement, i: Key) => {
								return (
									<tr key={i}>
										<th>
											<a
												href={`https://gsassembly-pcs.com/GSA-PCS/backend/web/Uploads/service_agreements/${sa.file}`}
												target="_blank" rel="noopener noreferrer"
											>
												view
											</a>
										</th>
										<th>{sa.start_date.split('T')[0]}</th>
										<th>{sa.end_date.split('T')[0]}</th>
										<th>{sa.regular_rate}</th>
										<th>{sa.supervisor_rate}</th>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
			<Modal show={create} onHide={handleClose} fullscreen={true}>
				<Modal.Header closeButton>
					<Modal.Title>Provide supplier info</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/*@ts-ignore */}
					<SupplierInfoForm
						supplier={_supplier}
						onSubmit={() => handleClose(true)}
						onCreate={show_new_supplier}
						manex={manex}
					/>
				</Modal.Body>
			</Modal>
			{_supplier && (
				<Modal show={create2} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Link to Manex Customer</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{/*@ts-ignore */}
						{/*<LinkManexCustForm
							customer={_customer}
							//manexCustomers={manexCustomers}
							handleSuccessfulAssociation={(cid, mcid) => {
								//update customers
								const newCustomers = customers.map((cust) => {
									if (cust._id === cid) {
										cust['manexCustomerId'] = mcid
									}
									return cust
								})
								if (setCustomers) setCustomers(newCustomers);
								handleClose()
							}}
						/>*/}
					</Modal.Body>
				</Modal>
			)}
			<Modal show={newUser} onHide={() => setNewUser(false)}>
				<Modal.Body>
					<Form className="mb-2">
						<Form.Group className="mb-3" controlId="username">
							<Form.Label className="freq">Username</Form.Label>
							<Form.Control type="text" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="email">
							<Form.Label className="freq">Email</Form.Label>
							<Form.Control type="email" placeholder="user@email.com" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="firstName">
							<Form.Label className="">First Name</Form.Label>
							<Form.Control type="text" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="lastName">
							<Form.Label className="">Last Name</Form.Label>
							<Form.Control type="text" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="title">
							<Form.Label className="">Title</Form.Label>
							<Form.Control type="text" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="phone">
							<Form.Label className="">Phone</Form.Label>
							<Form.Control type="text" />
						</Form.Group>
						<Form.Check
							label={
								<p>
									Send invitation email <i className="fa-solid fa-envelope" />
								</p>
							}
							id="autoemail"
							defaultChecked
						/>
					</Form>
					<Stack direction="horizontal" gap={1}>
						<Button
							variant="primary"
							onClick={callUserCreation}
							disabled={creating}
							className="btn-lg"
						>
							Create
						</Button>
						{creating && <Spinner animation="border" />}
					</Stack>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default SuppliersPage;
