import axios from "axios";
import { serverDomain } from "../fSettings";

const apiUrl = `${serverDomain}/api/sorts_and_pos`;

const auth = axios.create({ withCredentials: true });

export type SortAndPoRow = {
	sort: string,
	invoiced: number,
	uninvoiced: number,
	po: string | null,
	po_balance: number | null
}

export async function fetchSortsAndPos(token: string, customerID: string) {
	return auth.get(`${apiUrl}/sorts_and_pos/${customerID}`, {
		headers: { "x-token": token },
	});
}