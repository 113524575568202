import { useContext, useEffect, useState } from "react";
import {
	Alert,
	Button,
	Card,
	Container,
	Row,
	Col,
	Form,
	InputGroup,
	Stack,
	Tab,
	Tabs,
	Table,
	Modal
} from "react-bootstrap";
import { FormProvider, useForm, useFormContext, Controller } from "react-hook-form";
import React from "react";
import {
	createCustomer,
	CustomerContext,
	deleteCustomer,
	updateCustomer,
	apiUrl,
	createNewContact,
	updateContact
} from "../services/custService";
import { UserContext, xTokenContext } from "../services/authService";
import { useNavigate } from "react-router-dom";
import { CreateContactModal } from '../components/CreateContactModal'
import { EditContactModal } from "../components/EditContactModal";

export default function CustomerInfoForm(props) {
	const { xToken, setXToken } = useContext(xTokenContext);
	const { user, setUser } = useContext(UserContext);
	const { customer, setCustomer } = useContext(CustomerContext);

	const [key, setKey] = useState("supplier");

	const [createContact, setCreateContact] = useState(false)
	const [editContact, setEditContact] = useState(false)
	const [contacts, setContacts] = useState([])
	const [contactGettingCreated, setContactGettingCreated] = useState({
		FIRSTNAME: '',
		LASTNAME: '',
		EMAIL: '',
		TITLE: '',
		WORKPHONE: '',
		DEPARTMENT: '',
		CONTNOTE: ''
	})
	const [contactGettingEdited, setContactGettingEdited] = useState({})

	const navigate = useNavigate();

	const methods = useForm();
	const register = methods.register;

	useEffect(() => {
		var _form = props.customer;
		if (!_form) return;
		console.log("render customer", _form);
		const keys = Object.keys(_form);
		const values = Object.values(_form);
		for (let i = 0; i < keys.length; i++) {
			if (keys[i] !== 'serviceAgreement' && keys[i] !== 'serviceAgreementStartDate' && keys[i] !== 'serviceAgreementEndDate') {
				methods.setValue(keys[i], String(values[i]));
			}
		}
		methods.setValue("mustAlwaysProvideSortNum", props.customer.mustAlwaysProvideSortNum)

		setContacts(props.contacts)
	}, []);

	const [step, setStep] = useState(1);
	const onSubmit = (data) => {
		if (!user.admin) return navigate("/");
		// if (data.serviceAgreement.length > 0 && (data.serviceAgreementStartDate.length <= 0 || data.serviceAgreementEndDate.length <= 0)) {
		// 	alert('You need to provide start date and end date of service agreement')
		// 	return
		// }

		// should customer's sorts default to hourly PO?
		//const defaultHourly = document.getElementById("canHourly").checked;
		var formData = new FormData()
		const dataAsArray = Object.entries(data)
		dataAsArray.forEach(item => {
			if(item[0] !== 'serviceAgreement'){
				if(item[0] === 'serviceAgreementStartDate' || item[0] === 'serviceAgreementEndDate') {
					if(data.serviceAgreement.length > 0) {
						formData.append(item[0], item[1]);
					}
				} else {
					formData.append(item[0], item[1]);
				}
			}else{
				if(data.serviceAgreement.length > 0){
					formData.append(item[0], item[1][0])
				}
			}
		})
		//formData.append('defaultHourly', defaultHourly)

		// form submission is from database, update doc in db
		//if (customer && !props.manex && props.customer) {
		if (!props.manex && props.customer) {
			return updateCustomer(xToken, props.customer._id, formData).then((res) => {
				props.onUpdate(res.data);
				props.onSubmit();
			});
		}
		// form submission is from manex list, add customer to database
		// createCustomer({ ...data, defaultHourly }, xToken).then((res) => {
		// 	console.log("created");
		// 	if (props.manex || !props.customer) props.onCreate(res.data);
		// });
		createCustomer(formData, xToken).then((res) => {
			console.log("created");
			if (props.manex || !props.customer) props.onCreate(res.data);
		});
		return props.onSubmit();
	};

	function next(e) {
		if (Object.entries(methods.formState.errors).length > 0) setKey("supplier");
		//e.preventDefault();
	}
	function deletePrompt() {
		var confirm = window.confirm(
			"Deleting this customer will disassosciate its users!"
		);
		if (!confirm) return;
		if (xToken)
			deleteCustomer(props.customer._id, xToken).then((res) => {
				props.onDelete(props.customer._id);
			});
	}

	const handleCloseNewContactModal = () => {
		setCreateContact(false)
	}

	const handleClickOnSaveNewContact = async () => {
		try {

			const res = await createNewContact(xToken, props.customer._id, contactGettingCreated)

			const newContact = res.data

			setContacts([...contacts, newContact])
			setCreateContact(false)

		} catch (err) {

			console.log(err)
			alert(err.response.data)

		}
	}

	const handleClickOnEdit = (contact) => {
		setContactGettingEdited(contact)
		setEditContact(true)
	}

	const handleClosingEditContactModal = () => {
		setEditContact(false)
		setContactGettingEdited({})
	}

	const handleChangetoContactGettingEdited = (newValue) => {
		setContactGettingEdited({...contactGettingEdited, ...newValue})
	}

	const saveEditedContact = async () => {
		try {

			const res = await updateContact(xToken, contactGettingEdited)
			const updatedContact = res.data

			handleClosingEditContactModal()

			if (updatedContact.CID) {
				let newContactList = contacts.map((contact) => {
					if (contact.CID == updatedContact.CID) {
						return updatedContact
					} else {
						return contact
					}
				})
				setContacts(newContactList)
			} else {
				let newContactList = contacts.map((contact) => {
					if (contact._id == updatedContact._id) {
						return updatedContact
					} else {
						return contact
					}
				})
				setContacts(newContactList)
			}

		} catch (err) {
			
			alert(err.response.data)

		}
	}

	const tabs = ["supplier", "contacts", "settings"];
	return (
		<div>
			<Form onSubmit={methods.handleSubmit(onSubmit)} style={{ padding: 10 }}>
				<FormProvider {...methods}>
					<Tabs activeKey={key} onSelect={(k) => setKey(k)}>
						<Tab
							eventKey="supplier"
							title={<p className="freq mb-0">Customer</p>}
						>
							<SupplierSection />
						</Tab>
						<Tab eventKey="contacts" title="Customer Contact">
							{props.manex || props.customer == null ? 'Create contacts after creating customer' : (
								<ContactSection
									contacts={contacts}
									contactsFromManex={props.contactsFromManex}
									handleClickOnNewContact={(value) => setCreateContact(value)}
									handleClickOnEdit={(contact) => handleClickOnEdit(contact)}
								/>
							)}
						</Tab>
						{/* <Tab eventKey="reports" title="Reporting">
							<ReportSection />
						</Tab>
						<Tab eventKey="accounts" title="Accounts Payable">
							<AccountsSection />
						</Tab> */}
						<Tab
							eventKey="settings"
							title={
								<p className="mb-0">
									<i className="fa-solid fa-gear"></i> Settings
								</p>
							}
						>
							<SettingsSection customer={props.customer} />
						</Tab>
					</Tabs>
				</FormProvider>
				<hr />
				<Stack
					direction="horizontal"
					gap={2}
					style={{ justifyContent: "space-between" }}
				>
					{/* <Button size="lg" onClick={next}>Next</Button> */}
					{key !== 'contacts' && (
						<>
							<input
								type="submit"
								className="btn btn-block btn-lg btn-primary btn-success"
								value="Save"
								onClick={next}
							/>
							{props.manex && step === 1 && (
								<input
									type="submit"
									className="btn btn-block btn-lg btn-dark"
									value="Add Now"
								/>
							)}
							{props.customer !== null && step === 1 && !props.manex && (
								<Button variant="danger" size="lg" onClick={deletePrompt}>
									<i className="fa-solid fa-trash"></i> Delete
								</Button>
							)}
						</>
					)}
				</Stack>
			</Form>
			<CreateContactModal
				show={createContact}
				contact={contactGettingCreated}
				handleChangetoContactGettingCreated={(newValue) => setContactGettingCreated({...contactGettingCreated, ...newValue})}
				handleCloseNewContactModal={handleCloseNewContactModal}
				handleClickOnSaveNewContact={handleClickOnSaveNewContact} 
			/>
			<EditContactModal
				show={editContact}
				contact={contactGettingEdited}
				handleClosingEditContactModal={handleClosingEditContactModal}
				handleChangetoContactGettingEdited={handleChangetoContactGettingEdited}
				saveEditedContact={saveEditedContact}
			/>
		</div>
	);
}

function SupplierSection() {
	const { register } = useFormContext();
	return (
		<>
			<Alert variant="light" style={{ margin: 0 }}>
				Who is the customer?
			</Alert>
			<Form.Control type="hidden" {...register("manexCustomerId")} />
			<Form.Group className="mb-3" controlId="supName">
				<Form.Label className="freq">Customer Name</Form.Label>
				<Form.Control
					type="name"
					{...register("supName", { required: true })}
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="supAddress">
				<Form.Label>Street Address</Form.Label>
				<Form.Control type="text" {...register("supAddress")} />
				<Form.Text className="text-muted">or PO Box</Form.Text>
			</Form.Group>
			<Stack direction="horizontal" gap={2}>
				<Form.Group className="mb-3" controlId="supCity">
					<Form.Label>City</Form.Label>
					<Form.Control type="text" defaultValue="" {...register("supCity")} />
				</Form.Group>
				<Form.Group className="mb-3" controlId="supState">
					<Form.Label>State</Form.Label>
					<Form.Control type="text" defaultValue="" {...register("supState")} />
				</Form.Group>
			</Stack>
			<Form.Group className="mb-3" controlId="supCountry">
				<Form.Label>Country</Form.Label>
				<Form.Control type="text" defaultValue="" {...register("supCountry")} />
			</Form.Group>
		</>
	);
}
function SalesSection() {
	const { register } = useFormContext();
	return (
		<>
			<Alert variant="light" style={{ margin: 0 }}>
				Who is the sales representative?
			</Alert>
			<Form.Group className="mb-3" controlId="salesName">
				<Form.Label>Name</Form.Label>
				<Form.Control
					type="name"
					{...register("salesName", { required: false })}
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="salesEmail">
				<Form.Label>Email</Form.Label>
				<Form.Control
					type="email"
					{...register("salesEmail", {
						required: false,
						pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
					})}
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="salesPhone">
				<Form.Label>Phone Number</Form.Label>
				<Form.Control
					type="phone"
					{...register("salesPhone", { required: false, minLength: 10 })}
				/>
			</Form.Group>
			{/* <Form.Group className="mb-3" controlId="salesFax">
				<Form.Label>Fax</Form.Label>
				<Form.Control type="text" {...register("salesFax")} />
			</Form.Group> */}
			<Form.Group className="mb-3" controlId="salesZip">
				<Form.Label>Zip Code</Form.Label>
				<Form.Control
					type="number"
					{...register("salesZip", { required: false, minLength: 5 })}
				/>
			</Form.Group>
		</>
	);
}

function ContactSection({contacts, contactsFromManex, handleClickOnNewContact, handleClickOnEdit}) {
	
	return (
		<>
			<Stack direction="horizontal">
				<div className="p-2 ms-auto"></div>
				<Button className="p-2" onClick={() => handleClickOnNewContact(true)}><i class="fa-solid fa-user-plus"></i></Button>
			</Stack>
			<Table striped bordered hover size="sm">
				<thead>
					<tr>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Email</th>
						<th>Phone</th>
						<th>Title</th>
						<th>Department</th>
						<th style={{ width: '30%' }}>Notes</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{contacts.map((c, index) => (
						<tr key={index}>
							<td>{c.FIRSTNAME}</td>
							<td>{c.LASTNAME}</td>
							<td>{c.EMAIL}</td>
							<td>{c.WORKPHONE}</td>
							<td>{c.TITLE}</td>
							<td>{c.DEPARTMENT}</td>
							<td>{c.CONTNOTE}</td>
							<td>
								<Button
									variant="info"
									onClick={() => handleClickOnEdit(c)}
								>
									<i class="fa-solid fa-pen-to-square"></i>
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	)

}

function ReportSection() {
	const { register } = useFormContext();
	return (
		<>
			<Alert variant="light" style={{ margin: 0 }}>
				Who should we send reports to?
			</Alert>
			<Form.Group className="mb-3" controlId="repName">
				<Form.Label>Contact Name</Form.Label>
				<Form.Control
					type="name"
					{...register("repName", { required: false })}
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="repEmail">
				<Form.Label>Email</Form.Label>
				<Form.Control
					type="name"
					{...register("repEmail", { required: false })}
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="repPhone">
				<Form.Label>Phone Number</Form.Label>
				<Form.Control
					type="name"
					{...register("repPhone", { required: false })}
				/>
			</Form.Group>
		</>
	);
}

function AccountsSection() {
	const { register } = useFormContext();
	return (
		<>
			<Form.Group className="mb-3" controlId="accName">
				<Form.Label>Contact Name</Form.Label>
				<Form.Control
					type="name"
					{...register("accName", { required: false })}
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="accEmail">
				<Form.Label>Email</Form.Label>
				<Form.Control
					type="name"
					{...register("accEmail", { required: false })}
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="accPhone">
				<Form.Label>Phone Number</Form.Label>
				<Form.Control
					type="name"
					{...register("accPhone", { required: false })}
				/>
			</Form.Group>
		</>
	);
}

function SettingsSection(props) {
	const { register, setValue } = useFormContext();
	setTimeout(() => {
		// document.getElementById("regRate").value =
		// 	(props.customer && props.customer.regularRate) || 0;
		setValue('regularRate', (props.customer && props.customer.regularRate) || 0)
		// document.getElementById("supRate").value =
		// 	(props.customer && props.customer.supervisorRate) || 0;
		setValue('supervisorRate', (props.customer && props.customer.supervisorRate) || 0)
		const hourly = document.getElementById("canHourly");
		if (!hourly) return; // modal was probably closed, element hidden
		hourly.checked = (props.customer && props.customer.defaultHourly) || false;
		const quantity = document.getElementById("canQuantity");
		quantity.checked =
			(props.customer && !props.customer.defaultHourly) || false;
	}, 500);
	return (
		<>
			<Stack direction="horizontal" gap={3} className="mb-5 mt-5">
				<span>Default to</span>
				<Form.Check
					type="radio"
					name="defpo"
					label="Hourly PO"
					id="canHourly"
				/>
				<Form.Check
					type="radio"
					name="defpo"
					label="Quantity PO"
					id="canQuantity"
				/>
			</Stack>
			<Form.Check
				type="checkbox"
				label="Must always provide sort number"
				{...register("mustAlwaysProvideSortNum")}
				className="mb-5"
			/>
			<p className="mb-2">
				<i className="fa-solid fa-circle-info"></i> These rates are inherited to
				new sorts only.
			</p>
			<Stack direction="horizontal" gap={2} className="mb-3">
				<Form.Label htmlFor="regRate" className="mb-0" style={{ flex: "20%" }}>
					Regular
				</Form.Label>
				<InputGroup>
					<InputGroup.Text>$</InputGroup.Text>
					<Form.Control
						placeholder="0.00"
						type="number"
						step="0.01"
						min="0"
						id="regRate"
						defaultValue={0}
						{...register("regularRate")}
					/>
					<InputGroup.Text>/hour</InputGroup.Text>
				</InputGroup>
			</Stack>
			<Stack direction="horizontal" gap={2}>
				<Form.Label htmlFor="supRate" className="mb-0" style={{ flex: "20%" }}>
					Supervisor
				</Form.Label>
				<InputGroup>
					<InputGroup.Text>$</InputGroup.Text>
					<Form.Control
						placeholder="0.00"
						type="number"
						step="0.01"
						min="0"
						id="supRate"
						defaultValue={0}
						{...register("supervisorRate")}
					/>
					<InputGroup.Text>/hour</InputGroup.Text>
				</InputGroup>
			</Stack>
		</>
	);
}

