import { useContext, useEffect, useState } from "react";
import {
	Button,
	Card,
	Form,
	Modal,
	Stack,
	Table,
	Badge,
	InputGroup,
	FloatingLabel,
	Tooltip,
	OverlayTrigger,
	ProgressBar
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
	logoutUser,
	resetUserPassword,
	UserContext,
	xTokenContext,
} from "../services/authService";
import {
	CustomerContext,
	CustomersContext,
	fetchCustomers,
	apiUrl as custApi,
	fetchServiceAgreements,
	PcsServiceAgreement,
	fetchValidServiceAgreementFile
} from "../services/custService";
import {
	SupplierContext,
	SuppliersContext,
	fetchSuppliers,
	apiUrl as supApi,
	//fetchServiceAgreements,
	//PcsServiceAgreement,
	//fetchValidServiceAgreementFile
} from "../services/supService";
import {
	fetchOpenSorts,
	fetchPurchaseOrders,
	fetchRecentSorts,
	searchForSorts,
	saveAdminNote
} from "../services/sortService";
import { saveAs } from 'file-saver';

function HomePage() {
	const { xToken, setXToken } = useContext(xTokenContext);
	const { user, setUser } = useContext(UserContext);
	const { customer, setCustomer } = useContext(CustomerContext);
	const { customers, setCustomers } = useContext(CustomersContext);
	const { supplier, setSupplier } = useContext(SupplierContext);
	const { suppliers, setSuppliers } = useContext(SuppliersContext);
	const navigate = useNavigate();

	const [support, setSupport] = useState(false); // form modal
	const [loading, setLoading] = useState(false); // api timer
	const [supported, setSupported] = useState(false); // api succeeded
	const [resetting, setResetting] = useState(false); // password modal

	const [openSorts, setOpenSorts] = useState([]);
	const [recentSorts, setRecentSorts] = useState([]);
	const [pos, setPos] = useState([]);

	const [search, setSearch] = useState(""); // text input
	const [searching, setSearching] = useState(false); // show spin icon if true
	const [sortResults, setSortResults] = useState([]);  // results from text input
	const [customerServiceAgreements, setCustomerServiceAgreements] = useState([])

	const [sortForComment, setSortForComment] = useState({})
	const [showSortCommentModal, setShowSortCommentModal] = useState(false)
	const [sortComment, setSortComment] = useState('')

	useEffect(() => {
		if (!xToken) navigate("/auth");
	}, []);

	useEffect(() => {
		console.log(user)
		if (xToken && user && user.enterprise) {
			console.log(user.enterprise);
			fetchPurchaseOrders(user.enterprise, xToken).then((res) => {
				setPos([...res.data]);
			});
			if (!customers || customers.length === 0)
				fetchCustomers(xToken).then((res) => setCustomers(res.data));
			if (!suppliers || suppliers.length === 0)
				fetchSuppliers(xToken).then((res) => setSuppliers(res.data));
			if (user.admin) {
				fetchOpenSorts(xToken).then((res) => {
					setOpenSorts(res.data);
				});
				fetchRecentSorts(xToken).then((res) => {
					setRecentSorts(res.data);
				});
			}
			if (!user.admin && user.enterprise) {
				fetchServiceAgreements(user.enterprise, xToken).then((res) => {
					setCustomerServiceAgreements(res.data)
				})
			}
		}
	}, [xToken, user]);
	async function confirm_logout() {
		await logoutUser();
		if (setXToken) setXToken(null);
		if (setUser) setUser(null);
		navigate("/auth");
	}

	function reset_password() {
		const _old = document.getElementById("curPass");
		const _new = document.getElementById("newPass");
		const _cnew = document.getElementById("cnewPass");

		if (!_old.value) return _old.focus();
		if (!_new.value) return _new.focus();
		if (_new.value !== _cnew.value) return _cnew.focus();

		resetUserPassword(user._id, _old.value, _new.value, xToken)
			.then(async (res) => {
				alert(res.data);
				await logoutUser();
				if (setXToken) setXToken(null);
				const username = user.username;
				if (setUser) setUser(null);
				navigate("/auth/" + username);
			})
			.catch((err) => {
				alert(err.response.data);
				if (String(err.response.data).toLowerCase().includes("incorrect"))
					_old.focus();
			});
	}
	function password_modal() {
		setResetting(true);
		setTimeout(() => {
			document.getElementById("curPass").focus();
		}, 400);
	}
	function search_input(e) {
		let code = e.charCode || e.keyCode;
		if (code === 13) search_sort();
	}
	function search_sort() {
		if (searching) return;
		if (search.trim() !== "") {
			if (xToken) {
				setSearching(true);
				setTimeout(() => {
					searchForSorts(search, xToken).then((res) => {
						let results = res.data;
						console.log("results", results);
						setSortResults(results);
						setSearching(false);
					});
				}, 500);
			}
		} else {
			document.getElementById("dashSearch").focus();
		}
	}
	function submit_support() {
		setLoading(true);
		setTimeout(() => {
			setSupported(true);
			setLoading(false);
			setSupport(false);
		}, 600);
	}
	function changeColorOfBar (percent) {
		if (percent > 80) {
			return 'success'
		} else if (percent > 20) {
			return 'warning'
		} else {
			return 'danger'
		}
	}
	function getSumOfSo (pos) {
		const sum = pos.reduce((acc, obj) => acc + obj.soamount, 0);
		return sum
	}
	function getSumOfPoBalance (pos) {
		const sum = pos.reduce((acc, obj) => acc + obj.po_balance, 0);
		return sum
	}
	function handleClickOnSortwithoutPo (sort) {
		setSortForComment(sort)
		setShowSortCommentModal(true)
		setSortComment(sort.adminNotesOnPo)
	}
	function submitSortComment () {
		alert('submitting')
	}
	function handleCloseSortCommentModal () {
		setSortForComment({})
		setShowSortCommentModal(false)
	}
	async function handleSubmitSortComment () {

		try {
			const res = await saveAdminNote(sortForComment._id, sortComment, xToken)
			const updatedSort = res.data

			//close modal
			setShowSortCommentModal(false)
			//empty comment state
			setSortComment('')
			//empty sort for comment
			setSortForComment({})
			//update list
			let updatedListOfPos = pos.map(custObj => {
				const custObjNew = custObj
				let isTheCustomer = false
				const new_sorts_list = custObj.sorts_with_no_pos.map(sort => {
					if (sort._id == updatedSort._id) {
						isTheCustomer = true
						return updatedSort
					} else {
						return sort
					}
				})
				if (!isTheCustomer) {
					return custObjNew
				} else {
					custObj.sorts_with_no_pos = new_sorts_list
					return custObjNew
				}
			})
			setPos(updatedListOfPos)
			
		} catch (err) {
			alert(err)
		}

	}
	return (
		<>
			<img src="/splash.png" style={{ maxWidth: "20vmin" }} />
			<InputGroup
				className="mt-2 mb-2"
				style={{ width: "clamp(300px, 100%, 700px)" }}
			>
				<div style={{ position: "relative", flexGrow: "1" }}>
					<Stack direction="horizontal">
						<Form.Control
							type="text"
							id="dashSearch"
							placeholder={"Search for something..."}
							style={{ borderRadius: 0 }}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							onKeyDown={search_input}
						/>
						<Button
							style={{
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
								borderLeft: 0,
								border: "1px solid #ced4da",
								flex: "30%",
							}}
							variant="light"
							onClick={search_sort}
							disabled={searching}
						>
							{!searching ? (
								<i className="fa-solid fa-magnifying-glass" />
							) : (
								<i className="fa-solid fa-spinner spinner" />
							)}
						</Button>
					</Stack>
					{search !== "" && (
						<div className="form-search-nest">
							<span className="tip">
								<i className="fa-solid fa-circle-info" /> Press{" "}
								<strong>Enter</strong> for a full search
							</span>
							{sortResults
								.filter((s) =>
									s.sortNumber.toLowerCase().includes(search.toLowerCase())
								)
								.map((s) => {
									return (
										<button
											className="form-search-item"
											onClick={() =>
												navigate("/sorts/" + s.enterprise + "/" + s.sortNumber)
											}
										>
											{s.sortNumber} <small>(Sort)</small>
										</button>
									);
								})}
							{customers &&
								customers
									.filter((c) =>
										c.supName
											.trim()
											.toLowerCase()
											.includes(search.toLowerCase())
									)
									.map((c) => {
										return (
											<button
												className="form-search-item"
												onClick={() => navigate("/sorts/" + c._id)}
											>
												{c.supName} <small>(Customer)</small>
											</button>
										);
									})}
						</div>
					)}
				</div>
			</InputGroup>
			{user && user.enterprise === "" && (
				<Card
					className="mt-4"
					style={{ padding: "20px", width: "clamp(300px, 100%, 700px)" }}
				>
					<h3 style={{ color: " #f39c12 " }}>
						<i className="fa-solid fa-circle-exclamation"></i> Account setup
						incomplete
					</h3>
					<p>Our admin team must link your user to a customer.</p>
					<ul>
						<li>
							We may still be setting things up.
							<br></br>Please allow up to 24 hours.
						</li>
						<li>Email support to request a status update.</li>
					</ul>
					<Button
						variant="warning"
						onClick={() => setSupport(true)}
						disabled={supported}
					>
						{!supported ? (
							<>
								<i className="fa-solid fa-headset"></i> Support
							</>
						) : (
							<>
								<i className="fa-regular fa-paper-plane"></i> Support ticket
								sent
							</>
						)}
					</Button>
				</Card>
			)}
			{(openSorts && openSorts.length > 0) ||
			(recentSorts && recentSorts.length > 0) ? (
				<Card
					style={{ padding: "20px", width: "clamp(300px, 100%, 700px)" }}
					className="mb-2"
				>
					<h3>Pending & Recent Sorts</h3>
					<table className="dashtable">
						<thead>
							<tr>
								<th>Status</th>
								<th>Sort Number</th>
								<th>Customer</th>
								<th>Start Date</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{openSorts.map((s) => {
								return (
									<tr>
										<th>
											<Badge bg="dark" pill>
												<i className="fa-solid fa-paper-plane"></i> Submitted
											</Badge>
										</th>
										<th>{s.sortNumber}</th>
										<th>
											{customers.find((c) => c._id === s.enterprise)?.supName}
										</th>
										<th>
											{new Date(s.startDate)
												.toUTCString()
												.split(":", 1)[0]
												.slice(0, -3)}
										</th>
										<th>
											<Button
												style={{ margin: 5 }}
												variant="warning"
												onClick={() =>
													navigate(
														"/sorts/" + s.enterprise + "/" + s.sortNumber
													)
												}
											>
												<i className="fa-regular fa-circle-right"></i>
											</Button>
										</th>
									</tr>
								);
							})}
							{recentSorts.map((s) => {
								return (
									<tr>
										<th>
											<Badge bg="success" pill>
												Approved
											</Badge>
										</th>
										<th>{s.sortNumber}</th>
										<th>
											{customers.find((c) => c._id === s.enterprise)?.supName}
										</th>
										<th>
											{new Date(s.startDate)
												.toUTCString()
												.split(":", 1)[0]
												.slice(0, -3)}
										</th>
										<th>
											<Button
												style={{ margin: 5 }}
												variant="dark"
												onClick={() =>
													navigate(
														"/sorts/" + s.enterprise + "/" + s.sortNumber
													)
												}
											>
												<i className="fa-regular fa-circle-right"></i>
											</Button>
										</th>
									</tr>
								);
							})}
						</tbody>
					</table>
				</Card>
			) : (
				<></>
			)}

			<Card style={{ padding: "20px", width: "clamp(300px, 100%, 700px)" }}>
						<Stack direction="vertical" gap={2}>
							{user && (
								<>
									<h3>Purchase Orders</h3>
									{!user.admin && (
										<Button size="md" onClick={() => navigate("/sorts")}>
											<i className="fa-solid fa-arrow-up-right-from-square"></i>{" "}
											Go to Sorts
										</Button>
									)}
									<div style={{ maxWidth: "100%", overflowY: "scroll" }}>
										{user.admin && (pos.map(p => (
											<div style={{marginBottom: "50px"}}>
												<h5>{p.customerName}</h5>
												<p>Sorts without POs</p>
												<Stack direction="horizontal" gap={2} className="flex-wrap mb-3">
													{p.sorts_with_no_pos.map(s => <Badge bg="warning" text="dark" onClick={() => handleClickOnSortwithoutPo(s)}>{s.sortNumber}</Badge>)}
												</Stack>
												<Table striped bordered hover size="sm">
													<thead>
														<tr>
															<th>PO</th>
															<th>Status</th>
															<th>SO ($)</th>
															<th>Spent ($)</th>
															<th>Remaining</th>
														</tr>
													</thead>
													<tbody>
														{p.open_pos.map((po) => {
															return (
																<tr>
																	<th>{po.pono}</th>
																	<th>
																		{/* {po.data == 1 ? (
																			po.SHIPPEDQTY === po.ORD_QTY ? (
																				<Badge bg="secondary">Closed</Badge>
																			) : (
																				<Badge bg="primary">Open</Badge>
																			)
																		) : (
																			<Badge bg="info">In Progress</Badge>
																		)} */}
																		<Badge bg="primary">Open</Badge>
																	</th>
																	<th>
																		{po.soamount}
																	</th>
																	<th>
																		{po.total_invoiced}
																	</th>
																	<th>
																		<ProgressBar 
																			striped
																			now={((po.po_balance / po.soamount) * 100)}
																			label={`${((po.po_balance / po.soamount) * 100).toFixed()}%`}
																			variant={changeColorOfBar(((po.po_balance / po.soamount) * 100).toFixed())}
																		/>
																	</th>
																</tr>
															);
														})}
													</tbody>
												</Table>
												{p.open_pos.length > 0 ? (
													<>
														<h6>Total PO Balance</h6>
														<ProgressBar 
															striped
															now={((getSumOfPoBalance(p.open_pos) / getSumOfSo(p.open_pos)) * 100)}
															label={`${((getSumOfPoBalance(p.open_pos) / getSumOfSo(p.open_pos)) * 100).toFixed()}%`}
															variant={changeColorOfBar(((getSumOfPoBalance(p.open_pos) / getSumOfSo(p.open_pos)) * 100).toFixed())}
														/>
													</>
												) : 'No POs'}
											</div>
										)))}
										{!user.admin && (
											<>
												<Table striped bordered hover size="sm">
													<thead>
														<tr>
															{/* <th>Sort #</th> */}
															<th>PO</th>
															<th>Status</th>
															<th>SO ($)</th>
															<th>Spent ($)</th>
															<th>Remaining</th>
														</tr>
													</thead>
													<tbody>
														{pos.map((po) => {
															return (
																<tr>
																	{/* <th>{po.purchaseOrder}</th> */}
																	<th>{po.pono}</th>
																	<th>
																		{/* {po.data == 1 ? (
																			po.SHIPPEDQTY === po.ORD_QTY ? (
																				<Badge bg="secondary">Closed</Badge>
																			) : (
																				<Badge bg="primary">Open</Badge>
																			)
																		) : (
																			<Badge bg="info">In Progress</Badge>
																		)} */}
																		<Badge bg="primary">Open</Badge>
																	</th>
																	<th>
																		{po.soamount}
																	</th>
																	<th>{po.total_invoiced}</th>
																	<th>
																		<ProgressBar 
																			striped
																			now={((po.po_balance / po.soamount) * 100)}
																			label={`${((po.po_balance / po.soamount) * 100).toFixed()}%`}
																			variant={changeColorOfBar(((po.po_balance / po.soamount) * 100).toFixed())}
																		/>
																	</th>
																</tr>
															);
														})}
													</tbody>
												</Table>
												{pos.length > 0 ? (
													<>
														<h6>Total PO Balance</h6>
														<ProgressBar 
															striped
															now={((getSumOfPoBalance(pos) / getSumOfSo(pos)) * 100)}
															label={`${((getSumOfPoBalance(pos) / getSumOfSo(pos)) * 100).toFixed()}%`}
															variant={changeColorOfBar(((getSumOfPoBalance(pos) / getSumOfSo(pos)) * 100).toFixed())}
														/>
													</>
												) : 'No POs'}

											</>
										)}
									</div>
								</>
							)}
						</Stack>
					</Card>
			{customer && (
				<>
					
					<Card
						style={{
							marginTop: 30,
							padding: 20,
							width: "clamp(300px, 100%, 700px)",
						}}
					>
						<h3>Service Agreement</h3>
						{customerServiceAgreements.map((sa) => {
							const start_date = sa.start_date.split('T')[0]
							const start_date_arr = start_date.split('-')
							const start_date_obj = new Date(start_date_arr[0], start_date_arr[1] - 1, start_date_arr[2])
							const start_date_timestamp = start_date_obj.getTime()
							const end_date = sa.end_date.split('T')[0]
							const end_date_arr = end_date.split('-')
							const end_date_obj = new Date(end_date_arr[0], end_date_arr[1] - 1, end_date_arr[2])
							const end_date_timestamp = end_date_obj.getTime()
							const current = new Date()
							const current_timestamp = current.getTime()
							if (start_date_timestamp <= current_timestamp && current_timestamp <= end_date_timestamp) {
								return (
									<Table striped bordered hover size="sm">
										<thead>
											<tr>
												<th>start date</th>
												<th>end date</th>
												<th>technician rate</th>
												<th>supervisor rate</th>
												<th>view</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{start_date}</td>
												<td>{end_date}</td>
												<td>{sa.regular_rate}</td>
												<td>{sa.supervisor_rate}</td>
												<td><Button onClick={() => {
													fetchValidServiceAgreementFile(sa.file, xToken).then(res => {
														const blob = new Blob([res.data], { type: 'application/pdf' });
														saveAs(blob, 'service-agreement.pdf')
													}).catch((error) => alert('Something went wrong while trying to fetch the service agreement file.'))
												}}><i className="fa-solid fa-download"></i></Button></td>
											</tr>
										</tbody>
									</Table>
								)
							}
						})}
					</Card>
				</>
			)}
			{user && (
				<Card
					style={{
						marginTop: 30,
						padding: 20,
						width: "clamp(300px, 100%, 700px)",
					}}
				>
					<h3>
						<i className="fa-solid fa-id-card-clip"></i> {user.username}
					</h3>
					<Stack direction="horizontal" gap={1} className="mb-2">
						<p className="m-0">
							<b>Email:</b> {user.email}
						</p>
						<Button variant="light">
							<i className="fa-solid fa-pen-to-square"></i>
						</Button>
					</Stack>
					<Stack direction="vertical" gap={1}>
						<Button variant="secondary" onClick={password_modal}>
							<i className="fa-solid fa-key" /> Change Password
						</Button>
						<Button variant="dark" onClick={confirm_logout}>
							Log Out
						</Button>
					</Stack>
				</Card>
			)}
			<Modal show={support} onHide={() => setSupport(false)}>
				<Modal.Header>
					<h3>Open support case</h3>
				</Modal.Header>
				<Modal.Body>
					<Stack direction="horizontal" gap={1} className="mb-2">
						<FloatingLabel label="Email address" style={{ flex: "100%" }}>
							<Form.Control
								type="text"
								value={user ? user.email : ""}
								disabled
							/>
						</FloatingLabel>
						{/* <Button
							variant="secondary"
							style={{ height: "100%", aspectRatio: "1/1" }}
						>
							<i className="fa-solid fa-pen-to-square"></i>
						</Button> */}
					</Stack>
					<Stack direction="horizontal" gap={1}>
						<small style={{ flex: "100%" }}>
							<i className="fa-solid fa-bell"></i> Admins will be notified of
							your account issue
						</small>
						<Button
							variant="dark"
							style={{ flexShrink: "0" }}
							onClick={submit_support}
							disabled={loading}
						>
							<i className="fa-solid fa-paper-plane" /> Submit ticket
						</Button>
					</Stack>
				</Modal.Body>
			</Modal>
			<Modal show={resetting} onHide={() => setResetting(false)}>
				<Modal.Header>
					<h3>Password Reset</h3>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className="mb-3" controlId="curPass">
						<Form.Label>Your current password</Form.Label>
						<Form.Control type="password" />
					</Form.Group>
					<Form.Group className="mb-3" controlId="newPass">
						<Form.Label>New password</Form.Label>
						<Form.Control type="password" />
					</Form.Group>
					<Form.Group className="mb-3" controlId="cnewPass">
						<Form.Label>Repeat new password</Form.Label>
						<Form.Control type="password" />
					</Form.Group>
					<Button onClick={reset_password}>Confirm</Button>
				</Modal.Body>
			</Modal>
			<Modal show={showSortCommentModal} onHide={handleCloseSortCommentModal}>
				<Modal.Header closeButton><Modal.Title>{sortForComment.sortNumber}</Modal.Title></Modal.Header>
				<Modal.Body>      
					<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
						<Form.Label>Comment about PO</Form.Label>
						<Form.Control as="textarea" rows={5} value={sortComment} onChange={(e) => setSortComment(e.target.value)}/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleSubmitSortComment}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default HomePage;
