import { Key, useContext, useEffect, useState } from "react";
import {
	Button,
	Badge,
	Form,
	Modal,
	Stack,
	Table,
	Toast,
	ToastContainer,
	InputGroup,
	Dropdown,
	OverlayTrigger,
	Tooltip,
	Alert,
	Row,
	FloatingLabel,
} from "react-bootstrap";
import {
	createUser,
	deleteUser,
	fetchInactiveUsers,
	fetchUsers,
	logoutUser,
	promoteUser,
	searchInviteToken,
	sendInviteLink,
	UserContext,
	UserData,
	xTokenContext,
} from "../services/authService";
import {
	CustomerContext,
	CustomerItem,
	CustomersContext,
	fetchCustomers,
} from "../services/custService";
import { useNavigate } from "react-router-dom";

export function copy_text(text: string, success?: () => void) {
	if (navigator.clipboard) {
		return navigator.clipboard.writeText(text).then(success);
	}
	// old browser fallback
	var temp = document.createElement("textarea");
	temp.value = text;
	temp.setAttribute("readonly", "");
	temp.style.display = "none";
	document.body.appendChild(temp);
	temp.focus();
	temp.select();
	document.execCommand("copy");
	document.body.removeChild(temp);
	if (success) success();
}
function UsersPage() {
	const { user, setUser } = useContext(UserContext); // current user
	const { xToken, setXToken } = useContext(xTokenContext); // access token
	const { customer, setCustomer } = useContext(CustomerContext);
	const { customers, setCustomers } = useContext(CustomersContext);

	const navigate = useNavigate();

	const [users, setUsers] = useState<UserData[]>([]); // currently active users
	const [search, setSearch] = useState(""); // searches emails and usernames
	const [adminFilter, setAdminFilter] = useState(false); // hide admin users?
	const [curUser, setCurUser] = useState<UserData | null>(null);
	useEffect(() => {
		if (curUser) {
			(document.getElementById("userCustomer") as HTMLInputElement).value =
				curUser.enterprise || "";
		}
	}, [curUser]);
	const [nusers, setNusers] = useState<UserData[]>([]); // users that must accept invite link

	const [assignee, setAssignee] = useState<UserData | null>(null); // shows modal to choose customer for user

	const [create, setCreate] = useState<boolean>(false); // shows modal to create a passwordless user

	const [toast, setToast] = useState("");
	const [toastError, setToastError] = useState("");

	useEffect(() => {
		if (xToken && user?.admin)
			fetchCustomers(xToken).then((res) => {
				if (setCustomers && customers.length === 0) setCustomers(res.data);
				fetchUsers(xToken).then((res) => {
					setUsers(res.data);
					fetchInactiveUsers(xToken).then((res) => {
						setNusers(res.data);
					});
				});
			});
		else if (xToken && !user?.admin) {
			fetchUsers(xToken).then((res) => {
				setUsers(res.data);
				fetchInactiveUsers(xToken).then((res) => {
					setNusers(res.data);
				});
			});
		}
	}, [xToken]);
	useEffect(() => {
		if (user?.admin && (!customers || customers.length === 0) && xToken)
			fetchCustomers(xToken).then((res) => {
				if (setCustomers) setCustomers(res.data);
			});
	}, [user, xToken]);
	function callPromotion(fromView = false) {
		var enterprise = (
			document.getElementById(
				!fromView ? "custSelect" : "userCustomer"
			) as HTMLSelectElement
		).value;
		let __user = !fromView ? assignee : curUser;
		if (enterprise === __user?.enterprise) return; // no change
		if (__user && xToken)
			promoteUser(__user._id, enterprise, xToken).then((res) => {
				var _users = users;
				var i = _users.findIndex((cust) => cust._id === __user?._id);
				_users[i].enterprise = enterprise;
				setUsers(_users);
				setAssignee(null);
				if (fromView) setCurUser(null);
				if (__user?._id === user?._id)
					logoutUser().then((res) => {
						if (setXToken) setXToken(null);
						alert("You must log into the portal again.");
						navigate("/auth/" + user?.email);
						window.location.reload();
					});
			});
	}

	function callUserCreation() {
		var username = document.getElementById("username") as HTMLInputElement;
		var email = document.getElementById("email") as HTMLInputElement;
		let admin =
			(document.getElementById("adminFlag") as HTMLInputElement)?.checked ||
			false;
		if (String(username.value).trim() === "") return username.focus();
		if (String(email.value).trim() === "") return email.focus();
		const _user = {
			username: (document.getElementById("username") as HTMLInputElement).value,
			email: (document.getElementById("email") as HTMLInputElement).value,
			enterprise: (document.getElementById("userCust") as HTMLSelectElement)
				.value,
			admin,
		};
		if (admin) {
			const _confirm = window.confirm(
				"This user will have administrator power, continue?"
			);
			if (!_confirm) return;
		}
		if (xToken)
			createUser(_user, false, xToken)
				.then((res) => {
					var _users = nusers;
					_users.push(res.data);
					setNusers(_users);
					setCreate(false);
				})
				.catch((err) => {
					setToastError(err.response.data);
				});
	}
	function fetchInviteToken(userID: string) {
		if (xToken)
			searchInviteToken(userID, xToken).then((res) => {
				var token = res.data;
				copy_text(window.location.origin + "/invite/" + token);
				setToast("Link copied!");
			});
	}
	function delete_user(userID: string, pending = false) {
		var confirm = window.confirm("Deleting this user is irreversible!");
		if (!confirm) return;
		if (xToken)
			deleteUser(userID, xToken).then((res) => {
				if (!pending) setUsers(users.filter((cust) => cust._id !== userID));
				else setNusers(nusers.filter((cust) => cust._id !== userID));
				setToast(res.data);
			});
	}
	function start_creation() {
		setCreate(true);
		setTimeout(() => {
			document.getElementById("username")?.focus();
		}, 500);
	}
	function send_invite(userID: string) {
		searchInviteToken(userID, xToken || "").then((res) => {
			const token = res.data;
			sendInviteLink(token, xToken || "")
				.then((res) => {
					setToast(res.data);
				})
				.catch((err) => {
					setToastError(err.response.data);
				});
		});
	}
	function admin_warning(e: any) {
		let warning = document.getElementById("adminWarn");
		if (!warning) return;

		warning.style.display = e.target.checked ? "block" : "none";
	}
	function customer_warning(e: any) {
		let warning = document.getElementById("customerWarn");
		if (!warning) return;

		warning.style.display = e.target.value === "" ? "block" : "none";
	}
	return (
		<>
			{user?.admin && (
				<Button onClick={start_creation} variant="outline-primary">
					<i className="fa-solid fa-user-plus"></i> New User
				</Button>
			)}
			<h3>
				Showing {user?.admin ? "all portal users" : customer?.supName.trim()} (
				{users.length})
			</h3>
			<InputGroup className="mb-2">
				<InputGroup.Text>
					<i className="fa-solid fa-magnifying-glass"></i>
				</InputGroup.Text>
				<Form.Control
					type="text"
					placeholder="Search for user..."
					value={search}
					id="usearch"
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Dropdown autoClose={"outside"}>
					<Dropdown.Toggle variant="secondary">
						<i className="fa-solid fa-filter"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => setAdminFilter(!adminFilter)}>
							<Form.Check
								type="checkbox"
								label="Hide admins"
								className="mt-2"
								checked={adminFilter}
							/>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</InputGroup>
			<Table
				striped
				hover
				bordered
				size="sm"
				variant="dark"
				className="thighlight"
			>
				<thead>
					<tr>
						<th>Username</th>
						<th>Email</th>
						<th>Customer</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{users
						.sort((a, b) => (a.enterprise > b.enterprise ? 1 : -1))
						.sort((a, b) => (a.admin && !b.admin ? -1 : 1))
						.filter(
							(u) =>
								u.email.toLowerCase().includes(search.toLowerCase()) ||
								u.username.toLowerCase().includes(search.toLowerCase())
						)
						.filter((u) => !adminFilter || !u.admin)
						.map((_user: UserData, i: Key) => {
							return (
								<tr key={i}>
									<th>
										{_user.admin && <i className="fa-solid fa-shield-halved" />}
										{_user.username}
									</th>
									<th>{_user.email}</th>
									<th>
										{_user.enterprise ? (
											<>
												{customers.find((cust) => cust._id === _user.enterprise)?.supName}
												<Badge bg="success">{customers.find((cust) => cust._id === _user.enterprise)?.manexCustomerId}</Badge>
											</>
										)
										: customers.length > 0 ? (
											<Button
												onClick={() => setAssignee(_user)}
												variant="outline-primary"
											>
												Assign
											</Button>
										) : (
											<Button
												onClick={() => navigate("/customers")}
												variant="primary"
											>
												<i className="fa-solid fa-plus"></i> New Customer
											</Button>
										)}
									</th>
									<th>
										<Stack direction="horizontal" gap={1}>
											<Button variant="light" onClick={() => setCurUser(_user)}>
												<i
													className="fa-solid fa-pen-to-square"
													style={{ color: "inherit" }}
												></i>
											</Button>
											{_user._id !== user?._id && !_user.admin && (
												<>
													<Button
														variant="danger"
														onClick={() => delete_user(_user._id)}
													>
														Delete
													</Button>
												</>
											)}
										</Stack>
									</th>
								</tr>
							);
						})}
				</tbody>
			</Table>
			{Object.entries(nusers).length > 0 && (
				<>
					<p>These users have to choose a password</p>
					<Table striped hover bordered size="sm" variant="dark">
						<thead>
							<tr>
								<th>Username</th>
								<th>Email</th>
								<th>Customer</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{nusers.filter(
							(u) =>
								u.email.toLowerCase().includes(search.toLowerCase()) ||
								u.username.toLowerCase().includes(search.toLowerCase())
						)
							.map((nuser, i) => {
								return (
									<tr key={i}>
										<th>
											{nuser.admin && (
												<i className="fa-solid fa-shield-halved" />
											)}
											{nuser.username}
										</th>
										<th>{nuser.email}</th>
										<th>
											{nuser.enterprise && (
											<>
												{customers.find((cust) => cust._id === nuser.enterprise)?.supName}
												<Badge bg="success">{customers.find((cust) => cust._id === nuser.enterprise)?.manexCustomerId}</Badge>
											</>
										)}
										</th>
										<th>
											<Stack direction="horizontal" gap={1}>
												{user?.admin && (
													<>
														<Dropdown autoClose>
															<Dropdown.Toggle variant="light"></Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() => fetchInviteToken(nuser._id)}
																>
																	<i className="fa-solid fa-copy" /> Copy Link
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={() => send_invite(nuser._id)}
																>
																	<i className="fa-solid fa-paper-plane" /> Send
																	Link
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
														<Button
															variant="danger"
															onClick={() => delete_user(nuser._id, true)}
														>
															Delete
														</Button>
													</>
												)}
											</Stack>
										</th>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</>
			)}
			<ToastContainer
				style={{
					position: "fixed",
					bottom: "0",
					left: "50%",
					transform: "translateX(-50%)",
				}}
				position="bottom-center"
			>
				<Toast
					bg="success"
					onClose={() => setToast("")}
					show={toast !== ""}
					delay={4000}
					autohide
				>
					<Toast.Header closeButton={false}>
						<i className="fa-solid fa-check"></i> Success
					</Toast.Header>
					<Toast.Body style={{ color: "#fff" }}>{toast}</Toast.Body>
				</Toast>
				<Toast
					bg="danger"
					onClose={() => setToastError("")}
					show={toastError !== ""}
					delay={4000}
					autohide
				>
					<Toast.Header closeButton={false}>
						<i className="fa-solid fa-xmark"></i> Action failed
					</Toast.Header>
					<Toast.Body style={{ color: "#fff" }}>{toastError}</Toast.Body>
				</Toast>
			</ToastContainer>
			<Modal show={curUser !== null} onHide={() => setCurUser(null)}>
				<Modal.Header>
					<h3>
						<i className="fa-solid fa-id-card-clip"></i> {curUser?.username}
					</h3>
				</Modal.Header>
				<Modal.Body>
					<p>
						<b>Email:</b>{" "}
						<a href={"mailto:" + curUser?.email}>{curUser?.email}</a>
					</p>
					<p>
						<b>Last Login:</b>{" "}
						{curUser?.lastLogin &&
							new Date(String(curUser?.lastLogin)).toDateString()}
					</p>
					<p>
						<b>Privileges:</b>{" "}
						{curUser?.admin ? (
							<>
								Admin{" "}
								<OverlayTrigger
									placement="top"
									overlay={<Tooltip>Full access. Can manage all data.</Tooltip>}
								>
									<i className="fa-solid fa-circle-info"></i>
								</OverlayTrigger>
							</>
						) : (
							<>
								Limited Access{" "}
								<OverlayTrigger
									placement="top"
									overlay={
										<Tooltip>
											Cannot view other customers. Can admit more users to
											assigned customer.
										</Tooltip>
									}
								>
									<i className="fa-solid fa-circle-info"></i>
								</OverlayTrigger>
							</>
						)}
					</p>
					<p>
						<b>Customer:</b>
						<select name="enterprise" id="userCustomer" disabled={!user?.admin}>
							<option value="">—No Customer—</option>
							{customers
								.sort((a, b) => (a.supName < b.supName ? -1 : 1))
								.map((cust, i: Key) => {
									return <option value={cust._id}>{cust.supName}</option>;
								})}
						</select>
					</p>
					{user?.admin && (
						<Button
							variant="success"
							className="btn-lg"
							style={{ width: "100%" }}
							onClick={() => callPromotion(true)}
						>
							Save Changes
						</Button>
					)}
				</Modal.Body>
			</Modal>
			<Modal show={create} onHide={() => setCreate(false)}>
				<Modal.Body>
					<Form>
						<Stack direction="vertical" gap={2}>
							<FloatingLabel
								label={
									<span>
										<i className="fa-solid fa-user" /> Username
									</span>
								}
								controlId="username"
							>
								<Form.Control type="text" />
							</FloatingLabel>
							<FloatingLabel
								label={
									<span>
										<i className="fa-solid fa-envelope" /> Email
									</span>
								}
								controlId="email"
							>
								<Form.Control type="email" placeholder="user@email.com" />
							</FloatingLabel>
							<Form.Check
								label={
									<p className="m-0">
										Send invitation email{" "}
										<i className="fa-solid fa-paper-plane" />
									</p>
								}
								id="autoemail"
								defaultChecked
							/>
						</Stack>
						<hr />
						{user?.admin && (
							<>
								<Form.Group className="mb-3" controlId="customer">
									<label htmlFor="userCust" style={{ marginRight: 5 }}>
										Assign Customer
									</label>
									<select
										name="customer"
										id="userCust"
										onChange={customer_warning}
									>
										<option value="">--No Customer--</option>
										{customers
											.sort((a, b) => (a.supName < b.supName ? -1 : 1))
											.map((cust, i: Key) => {
												return (
													<option value={cust._id} key={i}>
														{cust.supName}
													</option>
												);
											})}
									</select>
								</Form.Group>
								<Alert variant="secondary" id="customerWarn">
									User access will be very limited without a customer link.
								</Alert>
								<Stack direction="vertical" gap={1} className="mb-2">
									<Form.Check
										label={
											<p className="m-0">
												Make administrator{" "}
												<i className="fa-solid fa-shield-halved" />
											</p>
										}
										onChange={admin_warning}
										id="adminFlag"
									/>
									<Alert
										variant="warning"
										id="adminWarn"
										style={{ display: "none" }}
									>
										<i className="fa-solid fa-warning" /> This user will have{" "}
										<b>full access</b> to the portal.
									</Alert>
								</Stack>
							</>
						)}
						<Stack direction="horizontal" className="justify-content-end">
							<Button variant="primary" onClick={callUserCreation}>
								<i className="fa-solid fa-plus" /> Create User
							</Button>
						</Stack>
					</Form>
				</Modal.Body>
			</Modal>
			<Modal show={assignee !== null} onHide={() => setAssignee(null)}>
				<Modal.Header>
					<h3>Assigning customer for '{assignee?.username}'</h3>
				</Modal.Header>
				<Modal.Body>
					<Stack direction="horizontal" gap={1}>
						<select name="customer" id="custSelect">
							{customers.map((cust, i: Key) => {
								return <option value={cust._id}>{cust.supName}</option>;
							})}
						</select>
						<Button onClick={() => callPromotion()}>Confirm</Button>
					</Stack>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default UsersPage;
