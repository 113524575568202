import {
	Button,
	Form,
	Modal
} from "react-bootstrap";
import { newCustomerContact } from '../services/custService'

export interface CreateContactModalProps {
    show: boolean;
    contact: newCustomerContact;
    handleChangetoContactGettingCreated: (newValue:object) => void;
    handleCloseNewContactModal: () => void;
    handleClickOnSaveNewContact: () => void;
}

export const CreateContactModal = (props: CreateContactModalProps) => {
    return (
        <Modal show={props.show} onHide={props.handleCloseNewContactModal}>
            <Modal.Body>
                <Form className="mb-2">
                    <Form.Group className="mb-3" controlId="FIRSTNAME">
                        <Form.Label className="freq">First Name</Form.Label>
                        <Form.Control type="text" value={props.contact.FIRSTNAME} onChange={(e) => props.handleChangetoContactGettingCreated({FIRSTNAME: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="LASTNAME">
                        <Form.Label className="freq">Last Name</Form.Label>
                        <Form.Control type="text" value={props.contact.LASTNAME} onChange={(e) => props.handleChangetoContactGettingCreated({LASTNAME: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="EMAIL">
                        <Form.Label className="freq">Email</Form.Label>
                        <Form.Control type="text" value={props.contact.EMAIL} onChange={(e) => props.handleChangetoContactGettingCreated({EMAIL: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="WORKPHONE">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text" value={props.contact.WORKPHONE} onChange={(e) => props.handleChangetoContactGettingCreated({WORKPHONE: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="TITLE">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" value={props.contact.TITLE} onChange={(e) => props.handleChangetoContactGettingCreated({TITLE: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="DEPARTMENT">
                        <Form.Label>Department</Form.Label>
                        <Form.Control type="text" value={props.contact.DEPARTMENT} onChange={(e) => props.handleChangetoContactGettingCreated({DEPARTMENT: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="CONTNOTE">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control type="text" value={props.contact.CONTNOTE} onChange={(e) => props.handleChangetoContactGettingCreated({CONTNOTE: e.target.value})}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClickOnSaveNewContact}>Save changes</Button>
            </Modal.Footer>
        </Modal>
    )
}