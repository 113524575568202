import { useContext, useEffect, useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { useLocation, useNavigate } from "react-router-dom";
import {
	logoutUser,
	UserContext,
	xTokenContext,
} from "../services/authService";
import { CustomerContext } from "../services/custService";
import { SupplierContext } from "../services/supService";

export function SideButton(props: any) {
	const navigate = useNavigate();
	const _location = useLocation();
	const [active, setActive] = useState(false);
	useEffect(() => {
		setActive(_location.pathname == props.path);
	}, [_location.pathname]);
	return (
		<button onClick={() => navigate(props.path)} aria-active={active}>
			<i className={props.icon}></i>
			<p>{props.label}</p>
		</button>
	);
}
function SideNav(props: any) {
	const navigate = useNavigate();

	const { user, setUser } = useContext(UserContext);
	const { customer, setCustomer } = useContext(CustomerContext);
	const { supplier, setSupplier } = useContext(SupplierContext);
	const { xToken, setXToken } = useContext(xTokenContext);

	const [toggle, setToggle] = useState(true); // for collapsing menu on mobile

	async function confirm_logout() {
		var confirm = window.confirm("Are you sure you want to log out?");
		if (confirm) {
			await logoutUser();
			if (setXToken) setXToken(null);
			if (setUser) setUser(null);
			if (setCustomer) setCustomer(null);
			if (setSupplier) setSupplier(null);
			navigate("/auth");
			window.location.reload();
		}
	}
	return (
		<>
			<div id="sidenav" className={props.toggle ? "" : "collapsed"}>
				<SideButton path="/" icon="fa-solid fa-house" label="Dashboard" />
				{user?.admin && (
					<>
						<SideButton
							path="/customers"
							icon="fa-solid fa-users"
							label="Customers"
						/>
					</>
				)}
				{user?.admin && (
					<>
						<SideButton
							path="/suppliers"
							icon="fa-solid fa-truck"
							label="Suppliers"
						/>
					</>
				)}
				{!user?.admin && user?.enterprise !== "" && (
					<SideButton
						path="/sorts"
						icon="fa-regular fa-calendar-days"
						label="Sorts"
					/>
				)}
				{!user?.admin && user?.supid !== "" && (
					<SideButton
						path="/sortssupplier"
						icon="fa-regular fa-calendar-days"
						label="Sorts"
					/>
				)}
				{user && (user.admin || user.enterprise) && (
					<SideButton path="/users" icon="fa-solid fa-id-card" label="Users" />
				)}
				{user && (
					<button onClick={confirm_logout}>
						<i className="fa-solid fa-right-from-bracket"></i>
						<p>Log Out</p>
					</button>
				)}
				<button
					style={{ justifyContent: "center" }}
					onClick={props.onToggle}
					className="collapse"
				>
					<i className="fa-solid fa-caret-up"></i>
				</button>
			</div>
		</>
	);
}

export default SideNav;
