import axios from "axios";
import { createContext, Dispatch, SetStateAction } from "react";
import { serverDomain } from "../fSettings";

export const apiUrl = `${serverDomain}/api/cust`;

const auth = axios.create({ withCredentials: true });

export async function createCustomer(data: Object, token: string[]) {
	console.log("token", token);

	return auth.post(`${apiUrl}/create`, data, {
		headers: { "x-token": token, 'content-type': 'multipart/form-data' },
	});
}

export type ManexCustomer = {
	CUSTNO: String[];
	CUSTNAME: string;
	PHONE: String[];
	FAX: String[];
	SHIPTO: String;
	ADDRESS1: string;
	CITY: string;
	STATE: string;
	ZIP: String;
	COUNTRY: string;
	E_MAIL: String;
};
export type ManexSupplier = {
	SUPID: String;
	SUPNAME: string;
};

export interface PcsServiceAgreement {
	id: string;
	id_customer: string;
	file: string;
	start_date: string;
	end_date: string;
	regular_rate: string;
	supervisor_rate: string;
}

export type CustomerItem = {
	_id: string;
	supName: string;
	supAddress?: string;
	supCity: string;
	supState: string;
	supCountry: string;
	verified: boolean;
	defaultHourly?: boolean;
	regularRate: number;
	supervisorRate: number;
	mustAlwaysProvideSortNum: boolean;
	manexCustomerId: String;
	serviceAgreement: string;
	serviceAgreementStartDate?: string;
	serviceAgreementEndDate?: string;
	sa?: PcsServiceAgreement[]
};
export type SupplierItem = {
	_id: string;
	supName: string;
	supId: string;
};

export interface ICustomersContext {
	customers: CustomerItem[];
	setCustomers?: Dispatch<SetStateAction<CustomerItem[]>>;
}
export interface ISuppliersContext {
	suppliers: SupplierItem[];
	setSuppliers?: Dispatch<SetStateAction<SupplierItem[]>>;
}
export const CustomersContext = createContext<ICustomersContext>({
	customers: [],
});


export interface ICustomer {
	customer: CustomerItem | null;
	setCustomer?: Dispatch<SetStateAction<null>>;
}
export const CustomerContext = createContext<ICustomer>({ customer: null });

export async function viewCustomer(id: String | string, token: string) {
	return auth.get(`${apiUrl}/view/${id}`, { headers: { "x-token": token } });
}
export async function deleteCustomer(id: string, token: string) {
	return auth.delete(`${apiUrl}/delete/${id}`, {
		headers: { "x-token": token },
	});
}
export async function fetchCustomers(token: string) {
	return auth.get(`${apiUrl}/fetch`, { headers: { "x-token": token } });
}
export async function fetchCustomerUsers(customerID: string, token: string) {
	return auth.get(`${apiUrl}/users/${customerID}`, {
		headers: { "x-token": token },
	});
}
export async function fetchServiceAgreements(customerID: string, token: string) {
	return auth.get(`${apiUrl}/service-agreements/${customerID}`, {
		headers: { "x-token": token },
	});
}
export async function fetchValidServiceAgreementFile(filename: string, token: string) {
	return auth.get(`${apiUrl}/service-agreement-file/${filename}`, {
		headers: { "x-token": token },
		responseType: "arraybuffer"
	});
}
export async function verifyCustomer(
	token: string,
	customerID: String,
	verify: Boolean
) {
	return auth.put(
		`${apiUrl}/verify/${customerID}`,
		{ verify: verify },
		{ headers: { "x-token": token } }
	);
}

export async function updateCustomer(
	token: string,
	customerID: String,
	data: Object
) {
	return auth.post(
		`${apiUrl}/update/${customerID}`,
		data,
		{ headers: { "x-token": token, 'Content-Type': 'multipart/form-data' } }
	);
}

export async function createNewContact(
	token: string,
	customerId: String,
	data: Object
) {
	return auth.post(
		`${apiUrl}/createNewContact/${customerId}`,
		data,
		{ headers: { "x-token": token } }
	);
}

export async function updateContact(
	token: string,
	data: Object
) {
	return auth.post(
		`${apiUrl}/updateContact/`,
		data,
		{ headers: { "x-token": token } }
	);
}

export async function fetchContacts(
	token: string,
	customerId: String
) {
	return auth.get(
		`${apiUrl}/fetchContacts/${customerId}`,
		{ headers: { "x-token": token, 'Cache-Control': 'no-cache' } }
	);
}

export async function linkToManexCust(
	token: string,
	customerID: String,
	CUSTNO: String,
	custName: string
) {
	return auth.post(
		`${apiUrl}/link-manex-cust/${customerID}`,
		{ CUSTNO, custName },
		{ headers: { "x-token": token } }
	);
}

export function hasValidServiceAgreement (customer: CustomerItem) {
	if (customer.sa === undefined) return false;
	if (customer.sa.length <= 0) return false;
	const now = new Date()
	const sa = customer.sa.find(s => {
		return new Date(s.start_date) <= now && now <= new Date(s.end_date)
	})
	if (sa === undefined) return false;
	return true;
}

export type ManexCustomerContact = {
	CID: string;
	FIRSTNAME: string;
	LASTNAME: string;
	TITLE: string;
	EMAIL: string;
	WORKPHONE: string;
	DEPARTMENT: string;
	CONTNOTE: string;
};

export type PortalCustomerContact = {
	_id: string;
	customerId: string;
	FIRSTNAME: string;
	LASTNAME: string;
	TITLE?: string;
	EMAIL: string;
	WORKPHONE?: string;
	DEPARTMENT?: string;
	CONTNOTE?: string;
};

export type newCustomerContact = {
	FIRSTNAME: string;
	LASTNAME: string;
	TITLE?: string;
	EMAIL: string;
	WORKPHONE?: string;
	DEPARTMENT?: string;
	CONTNOTE?: string;
};