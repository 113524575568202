import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.scss";
import Authenticator from "./pages/Authenticator";

import {
	fetchXToken,
	UserData,
	xTokenContext,
	UserContext,
	fetchUser,
} from "./services/authService";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import HomePage from "./pages/Home";
import CustomerInfoForm from "./pages/CustForm";
import {
	CustomerContext,
	CustomerItem,
	CustomersContext,
	viewCustomer,
} from "./services/custService";
import {
	SupplierContext,
	SupplierItem,
	SuppliersContext,
	viewSupplier,
} from "./services/supService";
import SideNav from "./components/SideNav";
import SortsPage from "./pages/SortsPage";
import SortsSupplierPage from "./pages/SortsSupplierPage";
import CustomersPage from "./pages/CustomersPage";
import SuppliersPage from "./pages/SuppliersPage";
import UsersPage from "./pages/UsersPage";
import InvitePage from "./pages/InvitePage";
import ForgotPass from "./pages/ForgotPass";
import SortsAndPos from "./pages/SortsAndPos";

function App() {
	const [xToken, setXToken] = useState(null);
	const [user, setUser] = useState<UserData | null>(null);
	const [customer, setCustomer] = useState<any>(null);
	const [supplier, setSupplier] = useState<any>(null);
	const [customers, setCustomers] = useState<CustomerItem[]>([]);
	const [suppliers, setSuppliers] = useState<SupplierItem[]>([]);
	const [sideToggle, setSideToggle] = useState(false);

	var refreshInterval: any = null;
	useEffect(() => {
		if (!xToken && !localStorage.getItem("refreshFail")) {
			fetchXToken()
				.then((res) => {
					setXToken(res.data.token);
					if (refreshInterval === null) {
						refreshInterval = setInterval(refresh_token, 540000);
						console.log("interval armed");
					}
					fetchUser(res.data.userID, res.data.token).then((_res) => {
						setUser(_res.data);
					});
				})
				.catch((err) => localStorage.setItem("refreshFail", "true"));
		}
	}, []);
	function refresh_token() {
		console.log("refreshing token");

		fetchXToken()
			.then((res) => {
				console.log("fetched token", res.data);

				setXToken(res.data.token);
			})
			.catch((err) => {
				clearInterval(refreshInterval);
				refreshInterval = null;
			});
	}
	useEffect(() => {
		if (user && xToken) {
			if (!customer && user.enterprise) {
				// fetch customer data
				viewCustomer(user.enterprise, xToken).then((res) => {
					setCustomer(res.data);
				});
			}
			if (!supplier && user.supid) {
				// fetch supplier
				viewSupplier(user.supid, xToken).then((res) => {
					setSupplier(res.data)
				})
			}
		}
	}, [user]);

	function side_toggle() {
		setSideToggle(!sideToggle);
	}
	return (
		<>
			{/* <p>access token: {xToken || "null"}</p> */}
			<Router>
				<div id="navnest" className={user && user.admin ? "admin" : ""}>
					<img
						src="/bars-solid.svg"
						height={30}
						className="sideBtn"
						onClick={side_toggle}
					/>
					<Link to="/">
						<img className="icon" src="/icon.png" />
					</Link>
					<h1>{user && user.admin && "Admin "}Portal</h1>
					{user && (
						<p style={{ position: "absolute", right: 10, top: 0 }}>
							{user?.admin ? (
								<i className="fa-solid fa-shield-halved"></i>
							) : (
								<i className="fa-solid fa-user"></i>
							)}
							{user.username}
							<br></br>
							{!user.admin && customer && customer.supName}
						</p>
					)}
				</div>
				<div className="baseholder">
					<UserContext.Provider value={{ user, setUser }}>
						{user && <SideNav toggle={sideToggle} onToggle={side_toggle} />}
						<div
							id="main"
							className={!user ? "mobile" : ""}
							style={{ overflow: "hidden" }}
						>
							<CustomersContext.Provider value={{ customers, setCustomers }}>
								<CustomerContext.Provider value={{ customer, setCustomer }}>
									<SuppliersContext.Provider value={{ suppliers, setSuppliers }}>
										<SupplierContext.Provider value={{ supplier, setSupplier }}>
											<xTokenContext.Provider value={{ xToken, setXToken }}>
												<Routes>
													<Route path="/" element={<HomePage />} />
													<Route path="/auth/:uname?" element={<Authenticator />} />
													<Route path="/invite/:token" element={<InvitePage />} />
													<Route path="/form" element={<CustomerInfoForm />} />
													<Route path="/users" element={<UsersPage />} />
													<Route path="/customers" element={<CustomersPage />} />
													<Route path="/suppliers" element={<SuppliersPage />} />
													<Route path="/sorts" element={<SortsPage />} />
													<Route
														path="/sorts/:customerID"
														element={<SortsPage />}
													/>
													<Route
														path="/sorts/:customerID/:sortNumber"
														element={<SortsPage />}
													/>
													<Route
														path="/resetpass/:token"
														element={<ForgotPass />}
													/>
													<Route path="/sorts-pos/:customerID" element={<SortsAndPos />} />
													<Route path="/sortssupplier" element={<SortsSupplierPage />} />
													<Route
														path="/sortssupplier/:supplierID"
														element={<SortsSupplierPage />}
													/>
													<Route
														path="/sortssupplier/:supplierID/:sortNumber"
														element={<SortsSupplierPage />}
													/>
													<Route path="/sortssupplier-pos/:supplierID" element={<SortsAndPos />} />

												</Routes>
											</xTokenContext.Provider>
										</SupplierContext.Provider>
									</SuppliersContext.Provider>
								</CustomerContext.Provider>
							</CustomersContext.Provider>
						</div>
					</UserContext.Provider>
				</div>
			</Router>
		</>
	);
}

export default App;
